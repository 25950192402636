<template>
  <div>
    <el-dialog
        :visible.sync="coverConfigDialog"
        width="510px"
        :title="$t('detail_param.coverConfig')"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="topUpDialog"
        height="304px">
      <div>
        <div class="detail">
          <div>
            <div>{{ $t('detail_param.firstOrderMoney') }}</div>
<!--            <el-input style="color: red" disabled v-model="coverNeedData.firstOrderMoney"  class="alter-input"></el-input>-->
            <div style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #72777E;background: #F7F9FC;cursor: not-allowed">{{coverNeedData.firstOrderMoney}}</div>
            <div class="suffix unit-gray">USDT</div>
          </div>
          <div>
            <div>{{$t('detail_param.firstOrderTimes')}}</div>
<!--            <el-input disabled v-model="coverNeedData.firstOrderTimes"  class="alter-input"></el-input>-->
            <div style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #72777E;background: #F7F9FC;cursor: not-allowed">{{coverNeedData.firstOrderTimes}}</div>
            <div class="suffix unit-gray">倍</div>
          </div>
          <template v-for="(item,index) in coverConfig">
            <div>
              <div>第{{index+1}}次補倉跌幅</div>
              <el-input @change="checkChangeInput($event,'coverConfig',index,'decline')" @input="checkInput($event,'coverConfig',index,'decline',3)" v-model="item.decline"  class="alter-input"></el-input>
              <div class="suffix unit-gray">%</div>
            </div>
            <div>
              <div>第{{index+1}}次補倉倍數</div>
              <el-input @change="checkChangeInput($event,'coverConfig',index,'multiple')" @input="checkInput($event,'coverConfig',index,'multiple',2)" v-model="item.multiple"  class="alter-input"></el-input>
              <div class="suffix unit-gray">倍</div>
            </div>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="width: 100%;height: 50px;box-sizing: border-box;border-top: 1px solid #EAECEF;cursor: pointer;display: flex" class="footer-hover">
          <div @click="handleClickCancelConfig" style="border-right: #EAECEF 1px solid;width: 50%;box-sizing: border-box;line-height: 50px;text-align: center;color: #72777E">取消</div>
          <div @click="handleClickEnterConfig" style="width: 50%;box-sizing: border-box;line-height: 50px;text-align: center;color:#52C41A;">確定</div>
        </div>
      </span>
    </el-dialog>

    <el-dialog
        :visible.sync="coverCallbackDialog"
        width="510px"
        title="補倉回調"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="topUpDialog"
        height="304px">
      <div>
        <div class="detail callback">
          <div>
            <div>首單補倉回調</div>
<!--            <el-input disabled value="無,根據開單信號買入"  class="alter-input"></el-input>-->
            <div style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #72777E;background: #F7F9FC;cursor: not-allowed">無,根據開單信號買入</div>
          </div>
          <template v-for="(item,index) in coverCallback">
            <div>
              <div>第{{index+1}}次補倉回調</div>
              <el-input @change="checkChangeInput($event,'coverCallback',index,'backValue')" @input="checkInput($event,'coverCallback',index,'backValue',3)" v-model="item.backValue"  class="alter-input"></el-input>
              <div class="suffix unit-gray">%</div>
              <div v-if="coverConfig[index]">
                <div v-show="Number(item.backValue)>coverConfig[index].decline-0" style="color: #E6A23C" class="error-tip">補倉回調不建議大於補倉跌幅</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="width: 100%;height: 50px;box-sizing: border-box;border-top: 1px solid #EAECEF;cursor: pointer;display: flex" class="footer-hover">
          <div @click="handleClickCancelCallback" style="border-right: #EAECEF 1px solid;width: 50%;box-sizing: border-box;line-height: 50px;text-align: center;color: #72777E">取消</div>
          <div @click="handleClickEnterCallback" style="width: 50%;box-sizing: border-box;line-height: 50px;text-align: center;color:#52C41A;">確定</div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import martinDefaultParam from "./martinDefaultParam";
export default {
  name: "CoverConfig",
  data(){
    return{
      coverConfigDialog:false,
      coverCallbackDialog:false,
      coverConfig:[],
      coverCallback:[],
    //  传过来的参数
      tranParam:{},
      coverNeedData:{}
    }
  },
  mounted() {
  },
  methods:{
    /**
     * 恢复交易设置的修改参数
     */
    recoverAlterParam(){
      let {strategy_type,trend_cycle,billing_quantity,replenishment_conf_new,replenishment_callback_new}=this.$store.state.addAlterMartin
      this.coverConfig=JSON.parse(JSON.stringify(replenishment_conf_new))
      this.coverCallback=JSON.parse(JSON.stringify(replenishment_callback_new))
      this.tranParam.policyTypeValue=(Number(strategy_type)+1).toString()
      if (trend_cycle=='1') this.tranParam.trendPeriod='0'
      else if (trend_cycle=='15') this.tranParam.trendPeriod='1'
      else if (trend_cycle=='30') this.tranParam.trendPeriod='2'
      else if (trend_cycle=='60') this.tranParam.trendPeriod='3'
      this.tranParam.orderNumber=billing_quantity-1
    },
    //用户点击确认
    handleClickEnterConfig(){
      let isPass=false
      this.coverConfig.map(item=>{
        if (item.decline=='' ||item.multiple=='')  isPass=true
      })
      if(isPass) return  this.$message.error('补仓配置不能为空 !')
      this.$store.commit('changeAlterCoverConfig',1)
      this.$store.commit('changeCoverConfig',this.coverConfig)
      this.$emit('sentData',this.coverConfig,this.coverCallback)
      this.coverConfigDialog=false
    },
    handleClickEnterCallback(){
      let isPass=false
      this.coverCallback.map(item=>{
        if (item.backValue=='')  isPass=true
      })
      if(isPass) return  this.$message.error('补仓回调参数不能为空 !')
      this.$store.commit('changeAlterCoverCallback',1)
      this.$store.commit('changeCoverCallback',this.coverCallback)
      this.$emit('sentData',this.coverConfig,this.coverCallback)
      this.coverCallbackDialog=false
    },
    //用户点击取消
    handleClickCancelConfig(){
      this.coverConfigDialog=false
      if (this.$store.state.alterCoverConfig==1){
        this.coverConfig=JSON.parse(JSON.stringify(this.$store.state.coverConfig))
      }
      else {
        //TODO  恢复补仓配置的默认参数
        if (this.$store.state.addAlterMartin==1) this.changeCoverConfig(this.tranParam.policyTypeValue,this.tranParam.trendPeriod,this.tranParam.orderNumber,1)
        else this.coverConfig=JSON.parse(JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new))
      }
    },
    handleClickCancelCallback(){
      this.coverCallbackDialog=false
      if (this.$store.state.alterCoverCallback==1){
        this.coverCallback=JSON.parse(JSON.stringify(this.$store.state.coverCallback))
      }
      else {
        //TODO  恢复补仓回调的默认参数
        if (this.$store.state.addAlterMartin==1) this.changeCoverConfig(this.tranParam.policyTypeValue,this.tranParam.trendPeriod,this.tranParam.orderNumber,2)
        else this.coverCallback=JSON.parse(JSON.stringify(this.$store.state.addAlterMartin.replenishment_callback_new))
      }
    },

    //保存配置弹框上方显示所需的数据
    saveCoverNeedData(detailParams){
      this.coverNeedData=detailParams
    },


    //恢复补仓配置和补仓回调的默认参数
    changeCoverConfig(policyTypeValue,trendPeriod,orderNumber,type=0){   //默认为0 两个都修改为默认   1为补仓配置改为默认 2为补仓回调改为默认
      trendPeriod=1         //TODO
      this.tranParam={policyTypeValue,trendPeriod,orderNumber}
      console.log(policyTypeValue, trendPeriod, orderNumber, type);
      if (type==1 || type==0) this.coverConfig=[]
      if (type==2 || type==0) this.coverCallback=[]
      console.log(policyTypeValue, trendPeriod, orderNumber, type);
      if (policyTypeValue==1){
          if (trendPeriod==1){
            for (let i=1; i<=orderNumber;i++){
              if (type==1 || type==0) {
                if (i==1) this.coverConfig.push({decline:1,multiple:i==1 ? 2 :this.coverConfig[i-2].multiple*2,id:i})
                if (i<=3 && i>1) this.coverConfig.push({decline:1+parseFloat(this.coverConfig[i-2].decline),multiple:i==1 ? 2 :this.coverConfig[i-2].multiple*2,id:i})
                if (i>=4 && i<=5) this.coverConfig.push({decline:1.5+parseFloat(this.coverConfig[i-2].decline),multiple:i==1 ? 2 :this.coverConfig[i-2].multiple*2,id:i})
                if (i==6 || i>=8) this.coverConfig.push({decline:3.5+parseFloat(this.coverConfig[i-2].decline),multiple:i==1 ? 2 :this.coverConfig[i-2].multiple*2,id:i})
                if (i==7) this.coverConfig.push({decline:4.5+parseFloat(this.coverConfig[i-2].decline),multiple:i==1 ? 2 :this.coverConfig[i-2].multiple*2,id:i})
              }
              if (type==2 || type==0) this.coverCallback.push({backValue:0.15,id:i})
            }
          }
          if (trendPeriod==2){
            for (let i=1; i<=orderNumber;i++){
              if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
              if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
            }
          }
          if (trendPeriod==3){
            for (let i=1; i<=orderNumber;i++){
              if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
              if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
            }
          }
        }

      if (policyTypeValue==2){
        if (trendPeriod==1){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
        if (trendPeriod==2){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
        if (trendPeriod==3){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
      }

      if (policyTypeValue==3){
        if (trendPeriod==1){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
        if (trendPeriod==2){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
        if (trendPeriod==3){
          for (let i=1; i<=orderNumber;i++){
            if (type==1 || type==0) this.coverConfig.push({decline:policyTypeValue,multiple:trendPeriod,id:i})
            if (type==2 || type==0) this.coverCallback.push({backValue:trendPeriod,id:i})
          }
        }
      }

      if (this.$store.state.addAlterMartin!=1){
        if (this.$store.state.alterCoverConfig==0)  this.$store.state.addAlterMartin.replenishment_conf_new=JSON.parse(JSON.stringify(this.coverConfig))
        if (this.$store.state.alterCoverCallback==0) this.$store.state.addAlterMartin.replenishment_callback_new=JSON.parse(JSON.stringify(this.coverCallback))
      }
      if (this.$store.state.alterCoverConfig==1 || this.$store.state.coverCallback==1){
        this.$store.commit('changeCoverConfig',this.coverConfig)
        this.$store.commit('changeCoverCallback',this.coverCallback)
      }
      this.$emit('sentData',this.coverConfig,this.coverCallback)

    },
    checkInput(value,type,index, name, num) {       // 通过正则过滤小数点后两位  num为 1 是整数  2为 两位小数   3是三為小數
      if (num==1){
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\d{0})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      if (num==2){
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      if (num==3){
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\.?\d{0,3})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      this[type][index][name]=inputValue
    },
    checkChangeInput(value,type,index,name){
      if (value==0) {
        this[type][index][name]=''
        this.$message.warning('輸入參數不能為0 !')
      }
      if (name!='multiple'){
        if (value>=100) {
          this[type][index][name]=''
          this.$message.warning('輸入參數必須小於100 !')
        }
        if (type=='coverConfig' && this[type][index][name] < this[type][index-1][name]){
          this[type][index][name]=''
          this.$message.warning('当前补仓跌幅必须大于上一次补仓跌幅 !')
        }
      }
      if (name=='multiple'){
        if (value<1 && value>0) {
          this[type][index][name]=''
          this.$message.warning('补仓倍数不能小于1倍 !')
        }
      }

      // if (type== 'coverCallback' && Number(value)>Number(this['coverConfig'][index]['decline'])){
      //   this[type][index][name]=''
      //   this.$message.warning('輸入參數補倉回調需要小於補倉跌幅 !')
      // }
    },



  },
  watch:{
    coverConfigDialog(newValue){
      if (!newValue) this.$emit('openDetailDialog')
    },
    coverCallbackDialog(newValue){
      if (!newValue) this.$emit('openDetailDialog')
    },
  }
}
</script>

<style scoped>
.detail > div{
  width: calc(50% - 4px);
  display: inline-block;
  vertical-align: top;
}
.detail > div:nth-child(2n+1){
  margin-right: 8px;
}
.callback > div{
  width: 100%;
}
.callback > div:nth-child(2n+1){
  margin-right: 0px;
}
/*後綴的顯示*/
.detail > div{
  position: relative;
}
.detail >div .suffix{
  position: absolute;
  top: 43px;
  vertical-align: middle;
  right: 0;
}
.detail >div .unit-gray{
  font-size: 14px;
  color: #B0B5BA;
  line-height: 32px;
  padding-right: 12px;
  display: inline-block;
}
.detail >div .unit-blue{
  font-size: 14px;
  color: #AB54DB;
  line-height: 32px;
  padding-left: 11px;
  padding-right: 12px;
  display: inline-block;
  cursor: pointer;
  border-left: 1px solid #EAECEF;
}

.detail > div >div:first-child{
  font-size: 12px;
  font-weight: 400;
  color: #72777E;
  line-height: 17px;
  margin: 20px 0 6px 0 ;
}
/*錯誤提示*/
.error-tip{
  font-size: 12px;
  margin-top: 4px;
  color: #f56c6c;
}

/*label 文字*/
.label-div{
  font-size: 12px;
  font-weight: 400;
  color: #72777E;
  line-height: 17px;
  padding: 20px 0 6px 0 ;
}

>>> .el-dialog__body{
  padding-top: 0;
}
>>> .el-dialog__footer{
  padding: 0;
}

/*輸入框高度32px*/
>>>.alter-input input{
  height: 32px;
  line-height: 32px;
}
.footer-hover>div:hover{
  background: rgb(238, 239, 241);
}

@media screen and (max-width: 900px){
  >>>.el-dialog__footer {
    padding-top: 20px !important;
  }
}
.firstCover{
  padding: 10px 0 0 0 ;
}
</style>
