<template>
  <div class="all_content" style="overflow-x: auto;">
<!--    <draggable v-if="elementList != null && elementList.length !=0" class="draggable" v-model="elementList" group="people" @start="drag=true" @end="drag=false">-->
<!--      <div class="dragElement" :id="course(element.index_id, 1)" v-for="(element, index) in elementList" style="display: inline-block" :key="element.index_id" @click="changeTrade(element, (element.index_id))">-->
<!--        <img :id="course(element.index_id, 2)" style="width: 16px; vertical-align: middle;" :src="element.icon">-->
<!--        <span style="margin-left: 10px; vertical-align: middle">{{ element.value }}</span>-->
<!--        <img v-if="element.state == 1" style="width:18px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">-->
<!--      </div>-->
<!--    </draggable>-->

    <div v-if="elementList != null && elementList.length !=0" class="draggable" v-model="elementList" group="people" @start="drag=true" @end="drag=false">
      <div class="dragElement" :id="course(element.index_id, 1)" v-for="(element, index) in elementList" style="display: inline-block" :key="element.index_id" @click="changeApiInfo(element, (element.index_id))">
        <img :id="course(element.index_id, 2)" style="width: 16px; vertical-align: middle;" :src="element.icon">
        <span style="margin-left: 10px; vertical-align: middle">{{ element.value }}</span>
        <img v-if="element.state == 1" style="width:24px;vertical-align: middle;margin-left: 10px" src="../../assets/img/robot/running.gif">
      </div>
    </div>

    <!-- pc -->
    <el-card class="robot-earn" style="padding-bottom: 0px;height: 330px" id="robot-earn" shadow="hover">
      <div>{{ $t('robot.robot_monitoring') }}</div>
      <div class="trend">
<!--        <div class="trend-title">{{ $t('robot.robot_base_info') }}<span v-if="activity == 1 && (showUpDate>=new Date().getTime())" style="display:inline-block;margin-left:30px;color: #72777E">{{ $t('robot.fee_relief') }}，{{ upDateTime }}{{ $t('robot.expire') }}</span></div>-->
        <div style="text-align: left; position: relative">
<!--          <img v-if="robotInfo.state == 1" style="display: inline-block; width: 149px; height: 200px; margin: 0 5% 0px 10%;" src="../../assets/img/robot/robot_new_icon.gif"></img>-->
<!--          <img v-else style="display: inline-block; width: 149px; height: 200px; margin: 0 5% 0px 10%;" src="../../assets/img/robot/robot_new_icon.jpg"></img>-->
          <img v-if="chargeStyle && parseInt(chargeStyle) <= 1" style="display: inline-block; width: 200px; height: 200px;margin: 0 5% 0px 10%;" src="../../assets/img/robot/wali.png"></img>
          <img v-if="chargeStyle && parseInt(chargeStyle) > 1" style="display: inline-block; width: 200px; height: 200px;margin: 0 5% 0px 10%;" src="../../assets/img/robot/robot.gif"></img>

<!--          <img v-if="chargeStyle && parseInt(chargeStyle) <= 1" style="display: inline-block; width: 200px; height: 200px" src="../../assets/img/robot/wali.png"></img>-->
<!--          <img v-if="chargeStyle && parseInt(chargeStyle) > 1" style="display: inline-block; width: 200px; height: 200px" src="../../assets/img/robot/robot.gif"></img>-->
          <img v-if="(token && robotInfo.state == 1)&&(futuresException&&futuresException.scores!=100) && (linshiException.errorType != 5)" class="warnning" style="" src="../../assets/img/robot/warning.png" @click="showWarnPop()"></img>
          <div style="margin-top: 0px;display: inline-block;vertical-align: top;margin-left: 30px;line-height: 30px;">
            <div class="robot-msg" style="text-align: left;padding: 0px;height: 30px">
              <span style="color: #1E2329;display:inline-block;text-align:right;vertical-align: middle">{{ $t('robot.robot_name') }}：</span>
              <span class="robot_name_sp" v-if="isShowEditNiceName==false" style="">{{ nickname }}</span>
              <el-input id="nickName" v-focus class="set-nick" @blur="setNick()" v-if="isShowEditNiceName" v-model="setNickName" style="" placeholder="请输入"></el-input>
              <span v-if="isShowEditNiceName==false && (nickname == '' || nickname == null)" style="color:#AB54DB; margin-left: 10px;vertical-align: middle; font-size: 16px; border-bottom:1px #AB54DB solid; cursor: pointer" @click="setNickNameFocus">設置名稱</span>
              <img v-if="isShowEditNiceName==false && (nickname)" style="vertical-align: middle;margin-left: 10px;cursor: pointer" src="../../assets/img/safe/alter_icon.svg" @click="setNickNameFocus">
            </div>
            <div class="robot-msg" style="text-align: left;margin-top: 20px">
              <span style="color: #1E2329;display: inline-block;text-align:right;vertical-align: middle">{{ $t('robot.robot_run_state') }}：</span>
              <span :class="[robotInfo.state == 1 && !APIstatus && !stateNameOfstrategy?'run_status':(robotInfo.state == 1 && stateNameOfstrategy)?'sleep_status':'stop_status']">{{ robotInfo.stateName }}</span>
<!--              <img v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" style="vertical-align: middle" src="../../assets/img/user/discount.svg">-->
<!--              <img v-if="robotInfo.state == '1'" style="margin-left: 5px;vertical-align: middle" src="../../assets/img/robot/flow.gif">-->
            </div>
            <div class="robot-msg" style="text-align: left">
              <span style="color: #1E2329;display:inline-block;text-align:right;">{{ $t('robot.robot_run_time') }}：</span>
              <span style="color: gray">{{ robotInfo.runTime }}</span>
            </div>
            <button v-loading="isLoginning" v-if="robotInfo.state == '2' && robotInfo.bind_api == 1" style="color: white; border: none;background-color: #52C41A" @click="openAndCloseRobot()">{{ buttonName }}</button>
            <button v-loading="isLoginning" v-if="robotInfo.state == '1' && robotInfo.bind_api == 1" id="openAndClose" @click="openAndCloseRobot()">{{ buttonName }}</button>
            <el-button v-loading="isLoginning" class="robot_btn" v-if="robotInfo.state == '3' || robotInfo.state == '4' || robotInfo.bind_api != 1" disabled>{{ buttonName }}</el-button>
          </div>
        </div>
      </div>
      <div style="background-color: #eaedf1; height: 240px;width: 1px;padding: 0px;margin: 0px 0px 20px 0px;vertical-align: bottom;"></div>
      <div class="trend" style="display: inline-block;position:relative;">
        <div id="chart_pie" style="width: 100%;height:260px;margin-top: 22px; margin-left: 20px;"></div>
<!--        <div v-if="this.totalBalance" style="position: absolute;top: 40%; left: 37%;text-align: center; line-height: unset; color: #1E2329">-->
<!--          <span style="display: block;">{{ $t('robot.robot_total_assets') }}</span>-->
<!--          <span style="display: block;font-size: 14px;margin-top: 10px">{{this.totalBalance}}</span>-->
<!--          <span style="display: block; margin-top: 0px;font-size: 14px">USDT</span>-->
<!--        </div>-->
        <span style="display:block;position: absolute; top: 24px;left: 20px;font-size: 14px; color: #72777E;">{{ $t('robot.robot_asset_distribution') }}</span>
      </div>
    </el-card>

    <!-- mb -->
    <el-card class="robot-earn-mb" style="padding-bottom: 0px; margin-bottom: 12px;position: relative" id="robot-earn" shadow="hover">
      <div v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" style="width: 100%; position: absolute;top:0px;left:0;background-color: #FEFBF3">
        <span style="display: inline-block;padding: 10px 20px;color: #E3B936;font-size: 14px">{{ userInfo.charge_style2 == 2?'SVIP':userInfo.charge_style2 == 3?'YSVIP':'' }} {{ $t('robot.fee_relief_eight') }}</span>
      </div>
<!--      <div class="trend" style="width: 100%; margin-top: 10px">-->
      <div class="trend" style="width: 100%;" :style="{marginTop: (userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3)? '30px': '10px'}">
        <div style="width: 100%;">
          <div style="margin-top: 0px;">
            <span style="display:block; font-size:15px;font-family: PingFangTC-Regular, PingFangTC;font-weight: 400;color: #1E2329;">H i～<span>{{ userPhone }}</span>，{{ $t('robot.welcome_to') }}<span>{{ robotIndex == 1?'Binance':'Okx' }}</span>{{ $t('robot.robot_center') }}</span>
<!--            <span v-if="robotInfo.state == '2'">已</span>-->
            <span style="display:block; font-size:15px;font-family: PingFangTC-Regular, PingFangTC;font-weight: 400;color: #1E2329;margin-top: 10px">{{ $t('robot.robot') }}<span v-if="robotInfo.state == '2'" style="color: #FF4D4F">{{ robotInfo.stateName }}</span><span v-else-if="robotInfo.state == '1' && stateNameOfstrategy" style="color: #72777E">{{ robotInfo.stateName }}</span><span v-else style="color: #52C41A">{{ robotInfo.stateName }}</span>，{{ $t('robot.robot_run_time') }}<span></span>{{ robotInfo.runTime }}</span>
            <span v-if="activity == 1 && (showUpDate>=new Date().getTime())" style="display:inline-block;margin-top:10px;color: #1E2329;font-size: 15px">{{ $t('robot.fee_relief') }}，{{ upDateTime }} {{ $t('robot.expire') }}</span>
            <button v-loading="isLoginning" v-if="robotInfo.state == '2' && robotInfo.bind_api == 1" style="color: white;margin-top:20px;margin-bottom: 10px;background-color: #52C41A;border: none" @click="openAndCloseRobot()">{{ buttonName }}</button>
            <button v-loading="isLoginning" v-if="robotInfo.state == '1' && robotInfo.bind_api == 1" style="margin-top:20px;margin-bottom: 10px" @click="openAndCloseRobot()">{{ buttonName }}</button>
            <el-button v-loading="isLoginning" class="robot_btn" v-if="robotInfo.state == '3' || robotInfo.state == '4' || robotInfo.bind_api != 1" disabled style="margin-top:20px;margin-bottom: 10px" disabled>{{ buttonName }}</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <!--  機器人設置 -->
    <robot_strategy :name="APIkEY" :userInfos="userInfo" :robotInfo="elementItem" :surplusNumIn="surplusNum" :semiSurplusNumIn="semiSurplusNum" :APIstatusA="APIstatus"></robot_strategy>

    <el-card class="robot-earn-mb rank_div" style="padding: 0px !important; background-color: transparent" id="robot-earn" shadow="hover">
      <div class="trend" style="width: 49%; height: 160px; background:#FFFFFF linear-gradient(180deg, #FFFCEC 0%, #FAF1D7 100%); margin-top: 0px; vertical-align: middle; display: inline-block;position:relative;"
           @click="inputWeekList(2)">
        <div style="margin-left: 20px; margin-top: 25px">
          <span style="color: #E3B936; font-weight: 500;font-size: 18px;display: block">{{ $t('robot.revenue_list_of_week') }}</span>
          <span style="font-family: PingFangTC-Regular, PingFangTC;display: block;font-size: 16px;font-weight: 400;color: #B6B18C;">{{ $t('robot.my_ranking') }}：<span style="font-size: 14px" v-if="myRankNum2 == $t('robot.not_listed') || myRankNum2 > 3">{{ myRankNum2 }}</span><span v-else style="color: #E3B936;font-size: 14px">NO.{{myRankNum2}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span></span>
        </div>
        <img style="width: 35px; margin-left: 20px; position: absolute; bottom: 30px;" src="../../assets/img/robot/check_gold.png">
        <img style="width: 90px; position: absolute; right: 0px; bottom: 0px;" src="../../assets/img/robot/star.png">
      </div>

      <div class="trend" style="float:right; width: 49%; height: 160px; margin-top: 0px; vertical-align: middle; display: inline-block; background:#FFFFFF linear-gradient(180deg, #E9F4FE 0%, #D5E4FF 100%);position:relative;"
           @click="inputWeekList(1)">
        <div style="margin-left: 20px; margin-top: 25px">
          <span style="color: #AB54DB; font-weight: 500;font-size: 18px;display: block">{{ $t('robot.billing_list_of_week') }}</span>
          <span style="font-family: PingFangTC-Regular, PingFangTC;display: block;font-size: 16px;font-weight: 400;color: #8C9CB6;">{{ $t('robot.my_ranking') }}：<span style="font-size: 14px" v-if="myRankNum1 == $t('robot.not_listed') || myRankNum1 > 3">{{ myRankNum1 }}</span><span v-else style="color: #E3B936;font-size: 14px">NO.{{myRankNum1}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span></span>
        </div>
        <img style="width: 35px; margin-left: 20px; position: absolute; bottom: 30px;" src="../../assets/img/robot/check_blue.png">
        <img style="width: 90px; position: absolute; right: 0px; bottom: 0px;" src="../../assets/img/robot/tick.png">
      </div>
    </el-card>

    <!--  機器人收益排行-->
    <el-card id="robot_shouyi" class="robot-pc" shadow="hover">
      <div class="earn-query-top">
        <div class="title" style="display: inline-block; width: 50%">{{ $t('robot.robot_ranking') }}</div>
<!--        <div style="display: inline-block; width: 50%; text-align: right">-->
<!--          <span>{{ $t('robot.allow_other_users_to_view_my_policies') }}：</span>-->
<!--          <el-switch-->
<!--              v-model="value_sw"-->
<!--              active-value="0"-->
<!--              inactive-value="1"-->
<!--              @change="changeSwitch($event, value_sw)">-->
<!--          </el-switch>-->
<!--        </div>-->
        <div>
          <div style="display: inline-block; width: 48%; margin-top: 20px;vertical-align: top;">
            <span style="font-size: 14px; color: #72777E;">{{ $t('robot.revenue_list_of_week') }}（USDT）</span>
            <span style="font-size: 14px; color: #72777E;">({{ $t('robot.my_ranking') }}：<span v-if="myRankNum2 == $t('robot.not_listed') || myRankNum2 > 3">{{ myRankNum2 }}</span><span v-else style="color: #E3B936;">NO.{{myRankNum2}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span>)</span>
            <el-table
                v-if="UserProfitByWeekTop10.length != 0"
                :data="UserProfitByWeekTop10"
                :show-header="title_status"
                style="width: 100%; margin-top: 10px">
              <el-table-column
                  align="left"
                  type="index"
                  width="40px"
                  prop="$index+1">
                <template slot-scope="scope" style="text-align: center; padding-right: 0px;">
                  <img v-if="scope.row.rankNum==1" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no1.png')">
                  <img v-if="scope.row.rankNum==2" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no2.png')">
                  <img v-if="scope.row.rankNum==3" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no3.png')">
                  <span v-if="scope.row.rankNum > 3" style="color: #000613; font-size: 14px;width: 30px;text-align: center;display: inline-block;">{{ scope.row.rankNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  align="left"
                  width="260px"
                  prop="date">
                <template slot-scope="scope">
                  <!--                <img style="vertical-align:middle;width: 30px;height: 30px;border: 1px white solid;border-radius: 50%" :src="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')">-->
                  <pop_image style="vertical-align:middle;" :img-url="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')" :placement="'right'"/>
                  <!--                <span style="font-size: 14px;color: #000613; vertical-align: middle">{{ scope.row.mobile }}<span v-if="scope.row.name">({{ scope.row.name }})</span></span> 旧的-->
                  <div style="display:inline-block;font-size: 14px;color: #000613; vertical-align: middle;margin-left: 5px">
                    <span style="display: block;line-height: 17px">{{ scope.row.mobile }}</span>
                    <span style="display: block;line-height: 17px" v-if="scope.row.name">{{ scope.row.name }}</span>
                  </div>
<!--                  <img style="cursor: pointer; margin-left: 5px;height: 13px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" v-if="scope.row.vip_type == 1" @click="$router.push('grant_member')" src="../../assets/img/VIP.svg">-->
<!--                  <img style="cursor: pointer; margin-left: 5px;height: 13px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" v-if="scope.row.vip_type == 2" @click="$router.push('grant_member')" src="../../assets/img/SVIP.svg">-->
<!--                  <img style="cursor: pointer; margin-left: 5px;height: 16px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" v-if="scope.row.vip_type == 3" @click="$router.push('grant_member')" src="../../assets/img/ssvip.svg">-->
                  <el-tooltip v-if="scope.row.is_exclusive == 1" effect="dark" :content="'专属链接用户燃料限时98%折扣'" placement="top">
                    <span style="display: inline-block; background: #FA3D58;border: 2px solid #FFFFFF;border-radius: 25px 25px 25px 0px; width: 20px; height: 14px; line-height: 14px; font-size: 12px;font-weight: 700; text-align: center; color: #FEFEFE; margin-left: 5px;position: absolute; top: 10px; left: 119px">专</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                  align="right"
                  prop="profit">
                <template slot-scope="scope">
                  <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']"style="">{{ scope.row.trade_profit | roundTwoFilter}}</span>
                </template>
              </el-table-column>
              <!--              <el-table-column-->
              <!--                  align="right"-->
              <!--                  prop="address">-->
              <!--                <template slot-scope="scope">-->
              <!--                  <a :class="[scope.row.allow_view_position == null || scope.row.allow_view_position == 0?'check_cangwei':'uncheck_cangwei']" style=""-->
              <!--                     @click="checkEvery(scope.row.user_id, scope.row.allow_view_position,2)">{{ $t('robot.see_policies') }}</a>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
            </el-table>
            <div style="text-align: center; margin-top: 20px" v-if="UserProfitByWeekTop10==null||UserProfitByWeekTop10.length == 0">
              <span style="padding: 18px 0px;color:#909399;display: inline-block; border-top:1px #EBEEF5 solid; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
            </div>
          </div>
          <div style="display: inline-block; width: 48%; margin-top: 20px;vertical-align: top;position: relative; margin-left: 4%;">
            <span style="font-size: 14px; color: #72777E;">{{ $t('robot.billing_list_of_week') }}</span>
            <span style="font-size: 14px; color: #72777E;">({{ $t('robot.my_ranking') }}：<span v-if="myRankNum1 == $t('robot.not_listed') || myRankNum1 > 3">{{ myRankNum1 }}</span><span v-else style="color: #E3B936;">NO.{{myRankNum1}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span>)</span>
<!--            <el-tabs class="rank-deal" style="float: right; position: absolute; top: -14px; right: 0px;" v-model="userProfitNum" @tab-click="handleSelectDate">-->
<!--              <el-tab-pane :label="$t('robot.all')" name="0"></el-tab-pane>-->
<!--&lt;!&ndash;              <el-tab-pane :label="$t('robot.sj')" name="3"></el-tab-pane>&ndash;&gt;-->
<!--              <el-tab-pane :label="$t('robot.supreme_hedge')" name="6"></el-tab-pane>-->
<!--              <el-tab-pane :label="$t('robot.bb')" name="2"></el-tab-pane>-->
<!--&lt;!&ndash;              <el-tab-pane :label="$t('robot.ub')" name="1"></el-tab-pane>&ndash;&gt;-->
<!--              <el-tab-pane :label="$t('robot.grid')" name="4"></el-tab-pane>-->
<!--              <el-tab-pane :label="$t('robot.martin')" name="5"></el-tab-pane>-->
<!--            </el-tabs>-->
            <el-table
                v-if="userProfitByWeekTop10List.length != 0"
                :data="userProfitByWeekTop10List"
                :show-header="title_status"
                style="width: 100%; margin-top: 10px">
              <el-table-column
                  align="left"
                  type="index"
                  width="40px"
                  prop="$index+1"
                  style="padding-right: 0px">
                <template slot-scope="scope" style="text-align: center; padding-right: 0px;">
                  <img v-if="scope.row.rankNum==1" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no1.png')">
                  <img v-if="scope.row.rankNum==2" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no2.png')">
                  <img v-if="scope.row.rankNum==3" style="width: 30px;height: 30px" :src="require('../../assets/img/robot/no3.png')">
                  <span v-if="scope.row.rankNum > 3" style="color: #000613; font-size: 14px;width: 30px;text-align: center;display: inline-block;">{{ scope.row.rankNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  align="left"
                  width="260px"
                  prop="date">
                <template slot-scope="scope">
                  <!--                <img style="vertical-align:middle;width: 30px;height: 30px;border: 1px white solid;border-radius: 50%" :src="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')">-->
                  <pop_image style="vertical-align:middle;" :img-url="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')" :placement="'right'"/>
                  <!--                <span style="display:inline-block;font-size: 14px;color: #000613; vertical-align: middle;margin-left: 5px">{{ scope.row.mobile }}<span v-if="scope.row.name">({{ scope.row.name }})</span></span>--> <!-- 旧的 -->
                  <div style="display:inline-block;font-size: 14px;color: #000613; vertical-align: middle;margin-left: 5px">
                    <span style="display:block;line-height: 17px">{{ scope.row.mobile }}</span>
                    <span style="display:block;line-height: 17px" v-if="scope.row.name">{{ scope.row.name }}</span>
                  </div>
                  <el-tooltip v-if="scope.row.is_exclusive == 1" effect="dark" :content="'专属链接用户燃料限时98%折扣'" placement="top">
                    <span style="display: inline-block; background: #FA3D58;border: 2px solid #FFFFFF;border-radius: 25px 25px 25px 0px; width: 20px; height: 14px; line-height: 14px; font-size: 12px;font-weight: 700; text-align: center; color: #FEFEFE; margin-left: 5px;position: absolute; top: 10px; left: 126px">专</span>
                  </el-tooltip>
<!--                  <img v-if="scope.row.vip_type == 1" @click="$router.push('grant_member')" style="cursor: pointer; margin-left: 5px;height: 13px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" src="../../assets/img/VIP.svg">-->
<!--                  <img v-if="scope.row.vip_type == 2" @click="$router.push('grant_member')" style="cursor: pointer; margin-left: 5px;height: 13px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" src="../../assets/img/SVIP.svg">-->
<!--                  <img v-if="scope.row.vip_type == 3" @click="$router.push('grant_member')" style="cursor: pointer; margin-left: 5px;height: 16px" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" src="../../assets/img/ssvip.svg">-->
                </template>
              </el-table-column>
              <el-table-column
                  align="right"
                  prop="77單">
                <template slot-scope="scope">
<!--                  <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']" style="">{{ scope.row.trade_count }} {{ $t('robot.order') }}</span>-->
                  <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']" style="">{{ scope.row.yield?(scope.row.yield*100): '0' | roundTwoFilter}}%</span>
                </template>
              </el-table-column>
<!--              <el-table-column-->
<!--                  align="right"-->
<!--                  prop="address">-->
<!--                <template slot-scope="scope">-->
<!--                  <a :class="[scope.row.allow_view_position == null || scope.row.allow_view_position == 0?'check_cangwei':'uncheck_cangwei']" style=""-->
<!--                     @click="checkEvery(scope.row.user_id, scope.row.allow_view_position,1)">{{ $t('robot.see_policies') }}</a>-->
<!--                </template>-->
<!--              </el-table-column>-->
            </el-table>
            <div style="text-align: center; margin-top: 20px" v-if="userProfitByWeekTop10List==null||userProfitByWeekTop10List.length == 0">
              <span style="padding: 18px 0px;color:#909399;display: inline-block; border-top:1px #EBEEF5 solid; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!--           弹出框              -->
    <!--仓位分布图-->
    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogCangwei"
        width="90%"
        center
        style="height: auto;max-width: 600px;margin-top:80px;overflow: unset;">
      <div class="trend" style="display: inline-block;position:relative;">
        <div id="chart_pie_mb" style="width: 100%;height:260px;width:300px;"></div>
        <div v-if="this.totalBalance" style="position: absolute;top: 40%; left: 37%;text-align: center; line-height: unset; color: #1E2329">
          <span style="display: block;">{{ $t('robot.robot_total_assets') }}</span>
          <span style="display: block;font-size: 14px;margin-top: 10px">{{this.totalBalance}}</span>
          <span style="display: block; margin-top: 0px;font-size: 14px">USDT</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" style="width: 100%;" @click="dialogCangwei = false">{{ $t('robot.button_sure') }}</el-button>
  </span>
    </el-dialog>
    <!--看他人倉位設置-->
    <el-dialog
        class="dialog-mb-ub dialog-space"
        :title="cangweiName"
        :visible.sync="dialogCheckCangwei"
        width="fit-content"
        style="height: auto;overflow: scroll;min-width: 450px">
      <div v-if="rankNum == 1" style="height: 20px; border-left:1px #7b869e solid;top: 23px;left: 160px;position: absolute;">
        <span style="font-size: 16px; line-height: 20px; margin-left: 20px;">{{ $t('robot.cumulative_billing_volume_week') }}：{{ weekTradeCount }}{{ $t('robot.order') }}</span>
      </div>
      <div>
        <!-- 黃金三角 -->
        <div v-if="dialogHuangjinList.length != 0" class="shouyi_div" style="display: inline-block; width: 350px; min-width: 281px;margin-right: 20px; margin-bottom: 20px">
          <div style="height: 150px; background: #FFFAEF;position: relative">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="color:#1E2329;">{{ $t('robot.golden_triangle_arbitrage') }}</span>
              <span class="robot-add-des" style="color: #72777E">{{ $t('robot.transaction_pair_added') }}：{{ huangjinNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 136px; height: 140px;" src="../../assets/img/robot/pic_ua_new.png">
            </div>
            <img class="sj-svip-id" style="position:absolute; top: 0px;right: 0px" src="../../assets/img/robot/svip_id.png">
          </div>
          <div style="margin-top: 0px">
            <thead style="width: 100%;  display: inline-table;border-bottom: 1px #EAECEF solid;margin-bottom: -2px;">
            <tr style="width: 100%">
              <th class="tc th" style="">{{ $t('robot.init_space') }}</th>
              <th class="tc th">{{ $t('robot.side') }}</th>
              <th class="tc th">{{ $t('robot.trade_currency') }}</th>
            </tr>
            </thead>
            <div style="text-align: center; margin-top: 2px" v-if="dialogHuangjinList==null||dialogHuangjinList.length == 0">
              <span style="padding: 21px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
            </div>
            <table v-if="dialogHuangjinList.length!=0" class="table" style="width: 100%; display: inline-block;">
              <tbody style="width: 100%; display: inline-table;">
              <tr v-for="(item, i) in dialogHuangjinList" style="width: 100%;">
                <td class="tc" style="width: 100%; position: relative">
                  <table style="width: 100%">
                    <tr style="width: 100%;margin-top: 0px" v-for="(item, i) in item.symbols">
                      <td class="tc td" style="color: #5c6be8; cursor: pointer">
                        <span style="display:block; color: #E3B936">{{ item.coin_one }}</span>
                        <span style="display:block; color: #1E2329">{{ item.space }}</span>
                      </td>
                      <td class="tc td" style="color: #52C41A; cursor: pointer">⇀</td>
                      <td class="tc td" style="cursor: pointer">{{ item.coin_two }}</td>
                    </tr>
                  </table>
                  <img v-if="item.robot_type == 'bn'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/binance_sign.svg">
                  <img v-if="item.robot_type == 'ok'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/okex_sign.svg">
                  <div class="" style="position: relative;margin: 10px 0px -15px 0px; border-top:1px #EAECEF solid;"></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- BB交易 -->
        <div v-if="dialogBBList.length != 0" class="shouyi_div" style="display: inline-block; vertical-align: top;width:350px;min-width: 281px;margin-right: 20px; margin-bottom: 20px">
          <div style="height: 150px; background: #FFFAEF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.bb_trade') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ BBNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_bb_new.png">
            </div>
          </div>
          <el-table :data="dialogBBList" :header-cell-style="{color:'#72777E'}" :cell-style="{fontSize: '12px'}" style="width: 100%;display: inline-block;vertical-align: top;">
            <el-table-column align="center" prop="date" :label="$t('robot.init_space')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_one }}</span>
                <span style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('robot.side')">
              <template slot-scope="scope">
                <span style="font-size:17px;padding: 3px 0px 0px 0px;line-height: 6px;color: #52C41A;display: block">⇀</span>
                <span v-if="scope.row.space_two != 0" style="color: #FF4D4F;display: block;padding: 3px 0px 0px 0px;line-height: 0px; margin-top:5px;transform: scale(-1.2);font-size: 14px;">⇀</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" :label="$t('robot.trade_currency')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_two }}</span>
                <span v-if="scope.row.space_two != 0" style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space_two }}</span>
                <img v-if="scope.row.robot_type == 'bn'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/binance_sign.svg">
                <img v-if="scope.row.robot_type == 'ok'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/okex_sign.svg">
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- UB交易 -->
        <div v-if="dialogUBList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;width:350px;min-width: 281px;margin-right: 20px; margin-bottom: 20px">
          <div style="height: 150px; background: #FFFAEF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.ub_trade') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ UBNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_ub_new.png">
            </div>
          </div>
          <el-table :data="dialogUBList" :header-cell-style="{color:'#72777E'}" :cell-style="{fontSize: '12px'}" style="width: 100%;display: inline-block;vertical-align: top;">
            <el-table-column align="center" prop="date" :label="$t('robot.init_space')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_one }}</span>
                <span style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('robot.side')">
              <span style="font-size:14px;color: #52C41A;display: block">⇀</span>
            </el-table-column>
            <el-table-column align="center" prop="address" :label="$t('robot.trade_currency')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_two }}</span>
                <img v-if="scope.row.robot_type == 'bn'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/binance_sign.svg">
                <img v-if="scope.row.robot_type == 'ok'" style="position: absolute; top:0px; right: 0" src="../../assets/img/robot/okex_sign.svg">
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 网格 -->
        <div v-if="dialogGridList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;width:350px;min-width: 281px;margin-right: 20px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.grid_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ GRIDNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_grid_new.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 2px" v-if="dialogGridList==null||dialogGridList.length == 0">
            <span style="padding: 21px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogGridList" v-for="(item, i) in dialogGridList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin:10px">
              <div style="display:inline-block;width: 50%;vertical-align: top">
                <span style="display: block;color: #72777E">{{ $t('robot.deal_pair') }}</span>
                <span style="display: block;color: #AB54DB">{{ item.coin_one }} <span style="font-size:17px;line-height: 6px;color: #52C41A;display: inline-block">⇀</span> {{ item.coin_two }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.input_amount') }}</span>
                <span style="display: block;color: #1E2329">{{ item.after_space | roundEightFilter }}({{ item.coin_one}})</span>
                <span style="display: block;color: #1E2329">{{ item.front_space | roundEightFilter }}({{item.coin_two}})</span>
              </div>
            </div>
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.Price_range') }}({{ item.coin_one }})</span>
                <span style="display: block;color: #1E2329">{{ item.floor_price |roundEightFilter}}~{{ item.ceiling_price }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.number_of_grids') }}</span>
                <span style="display: block;color: #1E2329">{{ item.grid_number }}</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
        <!-- 馬丁 -->
        <div v-if="dialogMartinList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;width:350px;min-width: 281px;margin-right: 20px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.martin_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ MARTINNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_martin.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 2px" v-if="dialogMartinList==null||dialogMartinList.length == 0">
            <span style="padding: 21px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogMartinList" v-for="(item, i) in dialogMartinList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin:10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.deal_pair') }}</span>
                <span style="display: block;color: #AB54DB">{{ item.coin_one }} <span style="font-size:17px;line-height: 6px;color: #52C41A;display: inline-block">⇀</span> {{ item.coin_two }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.policy_type') }}</span>
                <span style="display: block;color: #1E2329">{{ item.strategy_type_name }}</span>
              </div>
            </div>
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.full_order_position') }}(U)</span>
                <span style="display: block;color: #1E2329">{{ item.full_space }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.stop_ratio') }}</span>
                <span style="display: block;color: #1E2329">{{ item.stop_ratio | roundEightFilter }}%</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
        <!-- 至尊对冲 -->
        <div v-if="dialogHedgeList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;width:350px;min-width: 281px;margin-right: 20px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.grid_contract_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ HEDGENum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_hedge.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 2px" v-if="dialogHedgeList==null||dialogHedgeList.length == 0">
            <span style="padding: 21px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogHedgeList" v-for="(item, i) in dialogHedgeList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.yield') }}(U)</span>
                <span style="display: block;color: #1E2329">{{ item.profit_rate ? item.profit_rate : '0.00' | roundEightFilter }}%</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.billing_quantity') }}</span>
                <span style="display: block;color: #1E2329">{{ item.trade_count  }}</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        class="dialog-mb-ub"
        :visible.sync="dialogMessageOfOkx"
        width="90%"
        center
        style="height: auto;max-width: 600px;margin-top:80px;overflow: unset;">
      <div style="top: 40%; left: 37%;text-align: left; line-height: unset; color: #1E2329">
        <a style="display: block; color: unset;width: fit-content; margin: 0px;cursor:pointer;text-decoration:underline;" href="/user/safe">{{$t('robot.bind_okx_api_tip')}}！</a>
        <div style="margin-top: 10px;">
          <a style="color:#AB54DB;" target="_blank" :href="$registered_address.okx_address()"><span>OKX{{ $t('robot.register_address') }}:</span>{{ $registered_address.okx_address() }}</a>
          <span style="display:inline-block;color: #E3B936;cursor: pointer;border-bottom: #E3B936 1px solid;line-height: 14px;width: fit-content;margin-left: 10px" @click.doThis="copy()">{{ $t('alter.copy') }}</span>
        </div>
        <span style="display:block;margin-top: 3px">({{$t('robot.only_accounts_registered')}})</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" style="width: 100%;" @click="dialogMessageOfOkx = false">{{ $t('robot.i_know') }}</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub novice_show"
        :visible.sync="isNoviceShow"
        width="90%"
        center
        :show-close="false"
        :close-on-click-modal="false"
        style="height: auto;max-width: 600px;margin-top:80px;overflow: unset;">
      <div slot="title" style="text-align: left">
        <span style="display: inline-block; font-size:18px; vertical-align:middle; font-weight: 600;color: #303133">{{$t('problem.must_read_newbies')}}</span>
        <img style="width: 16px; height: 16px; vertical-align:middle; margin-left:10px" src="../../assets/img/help/remind.png" mode="">
      </div>
      <el-divider class="novice_show_divider" style="padding: 0 !important;"></el-divider>
      <div style="top: 40%; left: 37%;text-align: left; line-height: unset; color: #1E2329">
        <div class="popup-bg" style="padding: 0px;">
          <div>
            <div style="margin-top: 10px">
              <span style="display: unset;width: 100%; font-size:14px; line-height: 24px;">{{$t('problem.must_read_newbies_content1_1')}}</span>
              <span style="display: unset;width: 100%; font-size:14px; line-height: 24px; color: #E12020">{{$t('problem.must_read_newbies_content1_2')}}</span>
              <span style="display: unset;width: 100%; font-size:14px; line-height: 24px;">{{$t('problem.must_read_newbies_content1_3')}}</span>
            </div>
            <div style="margin-top: 5px;">
              <span style="font-size:14px; line-height: 24px; color: #E12020">{{$t('problem.must_read_newbies_content2_0')}}</span>
              <span style="line-height: 24px;">{{$t('problem.must_read_newbies_content2')}}</span>
            </div>
            <div style="margin-top: 5px;">
              <span style="font-size:14px; line-height: 24px;">{{$t('problem.must_read_newbies_content3')}}</span>
<!--              <span style="line-height: 24px;">{{$t('problem.must_read_newbies_content2')}}</span>-->
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="go_and_open" type="primary" style="width: 100%;" @click="noviceShow">{{ $t('robot.i_know') }}</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub novice_show"
        :visible.sync="warnDialog"
        width="90%"
        center
        :show-close="true"
        :close-on-click-modal="false"
        style="height: auto;max-width: 600px;margin-top:80px;overflow: unset;">
      <div slot="title" style="text-align: left">
        <span style="display: inline-block; font-size:18px; vertical-align:middle; font-weight: 600;color: #303133">{{$t('robot.Abnormal_Reminder')}}</span>
        <img style="display: inline-block; width: 21px; vertical-align:middle; margin-left:10px" src="../../assets/img/robot/warning.png" mode="">
      </div>
      <el-divider class="novice_show_divider" style="padding: 0 !important;"></el-divider>
      <div style="margin: 20px 0; text-align: left">
        <div style="display: inline-block; position: relative">
          <el-progress type="circle" :width="70" :stroke-width="10" color="#BF5FC1" :percentage="percent" :show-text="false" style="vertical-align: middle"></el-progress>
          <span style="display: inline-block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); font-size: 16px">{{percent}}</span>
        </div>
        <span style="display: inline-block; vertical-align: middle; margin-left: 20px; font-size: 16px">{{$t('robot.warn_dialog_title')}}</span>
      </div>
      <el-divider class="novice_show_divider" style="padding: 0 !important;"></el-divider>
      <div style="top: 40%; left: 37%;text-align: left; line-height: unset; color: #1E2329; padding-bottom: 20px">
        <div class="popup-bg" style="border-radius: 8px; padding: 10px; background: #F7F9FC; margin-top: 20px;position: relative" v-for="(item, index) in warnList">
          <span style="display: block; font-size: 16px">{{item.title}}</span>
          <span style="display: block; margin-top: 5px">{{item.content}}</span>
          <div style="text-align: right; margin-top: 10px">
            <el-button style="height: 30px; line-height: 30px; width: 70px; color: #FFFFFF; padding: 0; border-radius: 8px; border: unset" @click="warnMenu(item)" :style="{background: item.level==1?'#FFC025':'#DC4D4D'}">{{ item.errorTypeName }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        class="dialog-mb-ub novice_show"
        :visible.sync="warnFillUpPositions"
        width="90%"
        center
        :show-close="true"
        :close-on-click-modal="false"
        style="height: auto;max-width: 600px;margin-top:80px;overflow: unset;">
      <div slot="title" style="text-align: left">
        <span style="display: inline-block; font-size:18px; vertical-align:middle; font-weight: 600;color: #303133">{{$t('robot.fill_up_positions')}}</span>
<!--        <img style="display: inline-block; width: 21px; vertical-align:middle; margin-left:10px" src="../../assets/img/robot/warning.png" mode="">-->
      </div>
      <el-divider class="novice_show_divider" style="padding: 0 !important;"></el-divider>
      <div style="margin: 20px 0; text-align: left">
        <div style="padding: 0 0px;position: relative;">
          <div>
            <span style="font-size: 14px;">{{$t('robot.supreme_hedge')}}{{ warnFillUpItem.futuresInfo?warnFillUpItem.futuresInfo.symbol:'' }}</span>
          </div>
          <div style="background: #fefefe;opacity: 0.05; width: 100%; height: 1px;margin: 12px 0;"></div>
          <div style="display: flex;justify-content: space-between;">
            <span style="font-size: 14px;color: #72777E">{{$t('robot.input_position')}}</span>
            <span style="font-size: 14px;color: #1E2329">{{ warnFillUpItem.futuresInfo?warnFillUpItem.futuresInfo.space:'' }} USDT</span>
          </div>
          <div style="display: flex;justify-content: space-between; margin-top:12px">
            <span style="font-size: 14px;color: #72777E;">{{$t('robot.exchange_assets')}}</span>
            <span style="font-size: 14px;color: #1E2329">{{ warnFillUpItem.futuresInfo?warnFillUpItem.futuresInfo.exchangeBalance:'' }} USDT</span>
          </div>
<!--          <div style="display: flex;justify-content: space-between; margin-top:12px">-->
<!--            <span style="font-size: 14px;color: #72777E;">{{$t('robot.position_floating')}}</span>-->
<!--            <span style="font-size: 14px;color: #1E2329">13000 USDT</span>-->
<!--          </div>-->

          <div style="margin-top: 20px;">
            <span style="display: block; color: #72777E;font-size: 14px;">{{$t('robot.input_fillup_position')}}</span>
            <div style="border: 1px solid #DCDFE6; width: 100%; height: 40px;border-radius: 5px;margin-top: 10px;position: relative;">
              <span style="color: #97979D;font-size: 14px;position: absolute;left: 10px;top: 50%;transform: translate(0, -50%);">{{$t('robot.amount')}}</span>
              <input style="display: inline-block;font-size: 14px; border: unset; background: transparent; position:absolute; right:50px; top:50%; transform: translate(0, -50%); text-align: right; width: 72%;padding: 10px 0;" :placeholder="'0~'+(warnFillUpItem.futuresInfo?warnFillUpItem.futuresInfo.shouldFill:'')" v-model="availableAmount" placeholder-style="color:#97979D; font-size: 14px"/>
              <span style="color: #97979D;font-size: 14px;position: absolute;right: 10px;top: 50%;transform: translate(0, -50%);">USDT</span>
            </div>
            <div style="float: right;margin-top: 3px;">
              <span style="font-size: 12px;color: #72777E;">{{$t('robot.available_fill_position')}}: {{warnFillUpItem.futuresInfo?warnFillUpItem.futuresInfo.canFill:''}} USDT</span>
              <span style="color: #AB54DB; font-size: 14px;display: inline-block; margin-left: 20px; cursor: pointer" @click="setAll()">{{$t('robot.all')}}</span>
            </div>
          </div>
        </div>
        <div style="text-align: right; margin-top: 40px">
          <el-button style="height: 40px; line-height: 40px; width: 100px; color: #FFFFFF; background: #AB54DB; padding: 0; border-radius: 8px; border: unset" @click="confirmAvailableAmount()">确认补齐</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getUserInfo} from "../../server/rebate";
import {
  getRobotInfo,
  getAssets,
  getAllCoins,
  getAllSymbolsByCoin,
  getAllSymbolsByCoin2,
  getAddSj, getAddUb, getAddBb, getCloseRobot, getOpenRobot, getSj,
  getBb, getUb, getSellOrdersByState, getSellOrdersByState2, delSj,
  delBb, delUb, getUserProfitByWeekTop10, getUserTradeCountByWeekTop10,
  getTradeCountRankByWeek, getMyRankByWeek, updateUserPower, getUserPosition,
  getUserWeekTradeCount, getIndex, getRobotList, getSymbolCount, saveRobotName, getUserPositionNew,
  getUserFuturesException, complementFuturesSpace
} from "../../server/robot";
import draggable from "vuedraggable"
import icon1 from '../../assets/img/robot/bian_click_icon.svg'
import icon2 from '../../assets/img/robot/okx_new.svg'
import icon3 from '../../assets/img/robot/bian_icon.svg'
import icon4 from '../../assets/img/robot/okx_grey_new.svg'
import {getOkApiInfo, getUserApiInfo} from "../../server/auth";
import Robot_strategy from "./Robot_strategy";
import {Base64} from "js-base64";
import {getUserPolicy} from "../../server/user";
import { Message } from 'element-ui';
import Pop_image from "../common/pop_image";
import Tooltip from "../common/tooltip";

export default {
  name: "Robot",
  data() {
    return {
      dialogHuangjin: false,
      dialogUB: false,
      dialogBB: false,
      addUBchecked: true, // 添加UB选框
      dialogLimit1: false, //上限提示框
      dialogLimit2: false, //上限提示框
      dialogLimit3: false, //开通svip提示
      dialogCangwei: false, // 手機查看倉位分佈
      dialogCheckCangwei: false, //看他人倉位設置
      // name: '小机器人',
      cangweiName: this.$t('robot.everyone_space'),
      title_status: false,
      buttonName: this.$t('robot.button_start_stop_name1'),
      stateNameOfstrategy: '',// 根據策略列表判斷機器人狀態 機器人運行狀態如果策略列表爲空 顯示休眠狀態
      //分頁
      capitalListTotal: 10,
      pageNum: 1,
      pageNumofMb: 1,
      totalPage: 0,
      totalPageofMb: 0,
      totalRowofMb: 10,
      noLoaded: false,

      robotInfo: {},// 机器人状态信息
      assetsInfo: [], // 获取分布数据
      robotIndex: '',
      totalBalance: '0', // 獲取分佈數據  總資產
      allCoins: [], // 獲取所有幣種
      allSymbolsByCoin: [], //根据币种获取交易对
      allSymbolsByCoin2: [], //根据币种获取交易对
      userProfitByWeekTop10List: [], //本周开单榜
      UserProfitByWeekTop10: [], //本周收益榜
      myRankNum1: 0, //我的排行 開單
      myRankNum2: 0, //我的排行 收益

      addHuangjinNum: 0,
      addBBNum: 0,
      addUBNum: 0,
      surplusNum: 3,
      semiSurplusNum: 8,
      weekTradeCount: 0, //本周累计开单量
      tradingVolume: 0, //已填加的交易对数量
      img_del: require('../../assets/img/robot/del_pre.png'),
      img_del1: require('../../assets/img/robot/del_pre.png'),
      img_del2: require('../../assets/img/robot/del_pre_select.png'),

      // 排行榜用參數
      huangjinNum: 0,
      BBNum: 0,
      UBNum: 0,
      GRIDNum: 0,
      MARTINNum: 0,
      HEDGENum: 0,
      rankNum: 1,
      rankDialog: '504px',

      addJiaoyiSj: '添加交易',
      addJiaoyiUb: '添加交易',
      addJiaoyiBb: '添加交易',

      coinName: '', // 币种名称
      coinName2: '', // 交易对中币种名称
      coinName3: '', // 交易对中币种名称
      valueLin1: '',
      valueLin2: '',
      valueLin3: '',

      userProfitNum: '0', // 0：全部 1：U币 2：币币 3：黄金三角 4：網格 5：馬丁
      strategy_type: '0',
      order_column: 'yield', // 传trade_count开单量  传yield收益率
      lishijiaoyi: 0, //0：當前委托  1：歷史委托  2：歷史成交
      lishijiaoyiValue: 1, // 1：當前委托中選項
      SelectJiaoyi2: 1, // 1全部 2全部成交 3部分成交 4已取消 5已過期
      lgShow: false,
      isShowEditNiceName: false, // 设置昵称弹出框
      setNickName: '',

      userInfo: {}, // 用戶信息
      userPhone: '', //用戶手機號
      nickname: '', // 机器人昵称
      stopAndStart: '',//机器人状态手机版
      value_sw: "1", // 0允許 1不允許
      chargeStyle: '', // 是否为1 vip，如果2 为 svip
      sjList: [], //黃金三角list
      bbList: [], // BBlist
      ubList: [], // UBlist

      lishijiaoyiObj: {},
      lishijiaoyiList_1: [],
      lishijiaoyiList_1_mb: [],

      // dialog查看仓位
      dialogHuangjinList: [],
      dialogBBList: [],
      dialogUBList: [],
      dialogGridList: [],
      dialogMartinList: [],
      dialogHedgeList: [],

      elementList:[],
      elementItem:{},
      APIstatus: '',
      APIkEY: '',
      requested: false,

      dateValue: [],
      date1: '',
      date2: '',
      huangjinvalue1: '',
      huangjinvalue2: '',
      huangjinvalue3: '',
      huangjinvalue4: '',
      huangjinvalue5: '',
      huangjinvalue6: '',

      token: '',
      rotateVal: 0,
      scroll:'',
      testUser:'false', //是否為公測用戶.
      upDateTime: '',
      showUpDate: '',
      activity: 0,
      dialogMessageOfOkx: false,
      isLoginning: false,
      robot_type: 1,
      isNoviceShow: false,
      futuresException: {},
      percent: 0,
      warnList: [],
      warnDialog: false,
      warnFillUpPositions: false,
      fillPositionAvailable: 200,
      availableAmount: "",
      exceptionInterval: null,
      linshiException: {},
      warnFillUpItem: {}
    }
  },

  // computed(){
  //   return {
  //     buttonName: this.$t('robot.button_start_stop_name1'),
  //   }
  // },

  provide(){
    return{
      updataState:this.updataState
    }
  },
  watch: {
    $route(){
      let scroll = this.$route.query.scroll;
      if (scroll && (this.userProfitByWeekTop10List.length != 0 || this.UserProfitByWeekTop10.length != 0)){
        this.scrollTo(scroll);
      }
    },

  },

  activated() {
    window.addEventListener('setItem', () => {
      this.upDateTime = localStorage.getItem('updateTime')
      var date = this.upDateTime.replaceAll('.','-')
      this.showUpDate = new Date(date).getTime()
    });
    this.getUserPolicy(10);
    this.getTradeCountRankByWeek(10)
    this.getMyRankByWeek(10)
    if(this.exceptionInterval){
      clearInterval(this.exceptionInterval)
      this.exceptionInterval = null
    }
    this.exceptionInterval = setInterval(()=>{
      this.getUserFuturesException(10)
    }, 60000)
  },

  created() {
    let goback = '0';
    if (sessionStorage.getItem('goBack')){
      goback = sessionStorage.getItem('goBack')
      sessionStorage.removeItem('goBack')
    }
    if (goback == '1'){
      // return
      this.dialogUB = true
    }
    if (sessionStorage.getItem('testUser')){
      this.testUser = Base64.decode(sessionStorage.getItem('testUser'))
    }
    this.changeScreenSize()
    this.token = localStorage.getItem('token')
    // this.showCangweiPic();
  },
  mounted() {
    if (localStorage.getItem('isNoviceShow') && localStorage.getItem('isNoviceShow') == 'false'){
      this.isNoviceShow = false
    } else {
      this.isNoviceShow = true
    }
    this.scroll = this.$route.query.scroll
    this.getRobotList()
    this.getUserTradeCountByWeekTop10();
    this.getTradeCountRankByWeek();
    this.getUserProfitByWeekTop10()
    this.getMyRankByWeek();
  },
  methods: {
    course(index, num) {
      if (num == 1) {
        return "click" + index;
      } else {
        return "img" + index;
      }
    },

    initData(){
      this.getUserInfo();
      this.getRobotInfo();
    },
    initData2(){
      this.getAssets();
    },

    updataState(stateName){ // 赋值机器人状态
      this.stateNameOfstrategy = stateName
      this.robotInfo.stateName = stateName
    },

    gotoVip(){
      if (this.APIstatus){
        this.$message.warning(this.APIstatus)
        return
      }
      this.$router.push('grant_member')
    },

    drawLine(trendChart) {
      //累计收益趋势图表
      const datas = this.assetsInfo
      const option = {
        tooltip: {
          trigger: 'item',
          // formatter:'{a} </br>{b}:{c} %'
          formatter: function (params) {
            // let tip = params.seriesName;
            let tip = '';
            // for(let i=0;i<params.length;i++) {
            tip += params.marker + ' ' + params.name + "&nbsp;&nbsp;&nbsp;&nbsp;" + params.value + '%'+'<br>';
            tip += params.marker + ' ' +'数量：' + "" + params.data.num;
            // }
            return tip;
          }
        },
        title : {
          show:true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
          text: this.$t('robot.robot_total_assets'),//主标题文本，'\n'指定换行
          target: null,//指定窗口打开主标题超链接，支持'self' | 'blank'，不指定等同为'blank'（新窗口）
          subtext: (this.totalBalance + '\n' + 'USDT'),//副标题文本，'\n'指定换行
          x:'38.6%',//水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: '38%',//垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
          textAlign: 'center',//水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center'
          backgroundColor: 'rgba(0,0,0,0)',//标题背景颜色，默认'rgba(0,0,0,0)'透明
          borderColor: '#1E2329',//标题边框颜色,默认'#ccc'
          borderWidth: 0,//标题边框线宽，单位px，默认为0（无边框）
          padding: 5,//标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
          itemGap: 10,//主副标题纵向间隔，单位px，默认为10
          textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: '400',
          },
          subtextStyle: {//副标题文本样式{"color": "#aaa"}
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: '#1E2329',
            lineHeight:18
          },
          zlevel: 0,//一级层叠控制。默认0,每一个不同的zlevel将产生一个独立的canvas，相同zlevel的组件或图标将在同一个canvas上渲染。zlevel越高越靠顶层，canvas对象增多会消耗更多的内存和性能，并不建议设置过多的zlevel，大部分情况可以通过二级层叠控制z实现层叠控制。
          z: 6,//二级层叠控制，默认6,同一个canvas（相同zlevel）上z越高约靠顶层。
        },
        legend: {
          data: this.assetsInfo,
          bottom: '5%',
          // left: '500',
          right: 'left',
          icon: 'roundRect',
          align: 'left',
          orient: 'vertical',  //垂直显示
          itemWidth: 8,  // 设置宽度
          itemHeight: 8, // 设置高度
          itemGap: 16, // 设置间距
          // x: 'right',
          x: '70%',
          y: '30%',
          formatter: function (name) {
            let target;
            for (let i = 0; i < datas.length; i++) {
              if (name == datas[i].name) {
                target = datas[i].value
                break;
              }
            }
            var arr = [
              name + "    " + target + '%',
            ]
            return arr.join('\n');
          },
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            left: 'left',
            align: 'left',
            radius: ['50%', '80%'],
            center: ['39.1%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: { //鼠标划入字体显隐
                show: true,
              }
            },
            labelLine: {
              show: false
            },
            data: this.assetsInfo,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'outer',
                  fontSize: 12,
                  color: '#72777E',
                  formatter: '{b}：{d}%'
                },
                labelLine: { //指示线状态
                  show: true,
                  smooth: 0.2,
                  length: 5,
                  length2: 10,
                  formatter: '{d}%',
                }
              }
            }
          }
        ]
      };

      trendChart.setOption(option)
      setTimeout(function() {
        window.onresize = function() {
          trendChart.resize();
        }
      }, 200)
    },
    handleSelectDate(e, num) {
      this.getUserTradeCountByWeekTop10();
      this.getTradeCountRankByWeek();
    },

    async changeSwitch(event, value) {
      if(this.value_sw == 0){
        this.value_sw = 1;
      }else {
        this.value_sw = 0
      }
      const res4 = await updateUserPower(this.token, value)
      if (res4.success) {
        // if(this.value_sw == '0'){
        //   this.value_sw = '1'
        // } else{
        //   this.value_sw = '0'
        // }
        this.getUserInfo()

      }
    },

    // 查看倉位圖
    showCangweiPic() {
      this.dialogCangwei = true;
      this.timer = setTimeout(() => {
        let trendChart = this.$echarts.init(document.getElementById('chart_pie_mb'));
        this.drawLine(trendChart);
        clearTimeout(this.timer)
      }, 1000)
    },
    inputWeekList(num) {
      // 1 本周開單榜   2 本周收益榜
      this.$router.push({name: 'week_list', query: {num: num}})
    },
    // 獲取本周開單榜Top10
    async getUserTradeCountByWeekTop10() {
      const res = await getUserTradeCountByWeekTop10(this.userProfitNum, this.order_column, this.strategy_type, 1)
      if (!res) return
      if (res.success) {
        this.userProfitByWeekTop10List = res.data
        this.userProfitByWeekTop10List.forEach(function (item, index) {
          if (item.user_id == '4392') { //4392
            item.vip_type = 3
          }
        })
        this.userProfitByWeekTop10List.sort(this.compare('rankNum'))
        if (this.scroll && this.userProfitByWeekTop10List.length != 0){
          this.scrollTo(this.scroll);
        }
      } else {
        this.$message.error(res.message)
      }
    },
    compare(p){ //这是比较函数
      return function (m, n) {
        var a = m[p];
        var b = n[p];
        return a - b; //升序
      }
    },
    // 獲取本周開單榜我的排名
    async getTradeCountRankByWeek(times) {
      const res = await getTradeCountRankByWeek(this.userProfitNum, this.order_column, 1, this.strategy_type, times)
      if (!res) return
      if (res.success) {
        if (res.data == null || res.data == '暫無數據' || res.data.rankNum > 450) {
          this.myRankNum1 = this.$t('robot.not_listed')
        } else {
          this.myRankNum1 = res.data.rankNum
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取本周收益棒TOP10
    async getUserProfitByWeekTop10() {
      const res = await getUserProfitByWeekTop10(this.userProfitNum, 1, this.strategy_type)
      if (!res) return
      if (res.success) {
        this.UserProfitByWeekTop10 = res.data
        for (let i=0; i<this.UserProfitByWeekTop10.length; i++){
          // this.UserProfitByWeekTop10[i].profit = this.UserProfitByWeekTop10[i].profit.toFixed(2)
          this.UserProfitByWeekTop10[i].trade_profit = (this.UserProfitByWeekTop10[i].trade_profit * 1)
          if (this.UserProfitByWeekTop10[i].user_id == '4392') { //4392
            this.UserProfitByWeekTop10[i].vip_type = 3
          }
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取本周收益棒我的排名
    async getMyRankByWeek(times) {
      const res = await getMyRankByWeek(this.userProfitNum, 1, times)
      if (!res) return
      if (res.success) {
        if (res.data == null || res.data == '暫無數據' || res.data.rankNum > 450) {
          this.myRankNum2 = this.$t('robot.not_listed')
        } else {
          this.myRankNum2 = res.data.rankNum
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // delPre(id) { // 当前委托的刪除
    //   this.$message.info(id)
    //
    // },
    async checkEvery(id, allow_view_position, num) {
      if (allow_view_position == 1) {
        return
      }
      this.rankNum = num
      this.dialogCheckCangwei = true;
      this.dialogUBList = []
      this.dialogBBList = []
      this.dialogHuangjinList = []
      this.huangjinNum = 0
      this.BBNum = 0
      this.UBNum = 0
      // this.rankDialog = '1457px'
      const res4 = await getUserPositionNew(id)
      if (res4 && res4.success) {
        if (res4.data) {
          for (let i = 0; i < res4.data.length; i++) {
            if (res4.data[i].product_type == 'sj') {
              this.dialogHuangjinList = res4.data[i].positions
              this.huangjinNum = res4.data[i].positions.length * 3;
            }
            if (res4.data[i].product_type == 'bb') {
              this.dialogBBList = res4.data[i].positions
              this.BBNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'ub') {
              this.dialogUBList = res4.data[i].positions
              this.UBNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'grid') {
              this.dialogGridList = res4.data[i].positions
              for (let i=0; i<this.dialogGridList.length;i++){
                this.dialogGridList[i].coin_one = this.dialogGridList[i].coin.split('-')[1]
                this.dialogGridList[i].coin_two = this.dialogGridList[i].coin.split('-')[0]
              }
              this.GRIDNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'martin') {
              this.dialogMartinList = res4.data[i].positions
              for (let i=0; i<this.dialogMartinList.length;i++){
                this.dialogMartinList[i].coin_one = this.dialogMartinList[i].coin.split('-')[1]
                this.dialogMartinList[i].coin_two = this.dialogMartinList[i].coin.split('-')[0]
                if (this.dialogMartinList[i].strategy_type == 0){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name0')
                } else if(this.dialogMartinList[i].strategy_type == 1){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name1')
                } else if(this.dialogMartinList[i].strategy_type == 2){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name2')
                }
              }
              this.MARTINNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'hedge') {
              this.dialogHedgeList = res4.data[i].positions
              for (let i=0; i<this.dialogHedgeList.length;i++){
                this.dialogHedgeList[i].coin_one = this.dialogHedgeList[i].coin.split('-')[1]
                this.dialogHedgeList[i].coin_two = this.dialogHedgeList[i].coin.split('-')[0]
              }
              this.HEDGENum = res4.data[i].positions.length;
            }

          }
        }
      } else {
        this.$message.error(res4.message)
      }
      if (num == 1) {
        await this.getUserWeekTradeCount(id)
      }
    },
    async getUserWeekTradeCount(uid) {
      const res = await getUserWeekTradeCount(uid)
      if (!res) return
      if(res.success){
        this.weekTradeCount = res.data.trade_count
      }
    },
    // 開啓關閉機器人跳板
    openAndCloseRobot() {
      if (this.robotInfo.state == 1) {
        this.$confirm(this.$t('robot.close_robot_sure'))
            .then(_ => {
              this.closeAndOpen();
            })
      } else {
        // if (this.robotInfo && this.robotInfo.robot_type == 1){
        //   this.$message.warning('即將上綫，敬請期待!')
        //   return;
        // }
        this.closeAndOpen();
      }
    },
    // 關閉機器人
    async closeAndOpen() {
      this.isLoginning = true
      if (this.robotInfo.state == 1) {
        const res = await getCloseRobot(this.robot_type)
        if (res.success) {
          this.isLoginning = false
          this.$message.success(this.$t('robot.robot')+this.$t('robot.closed'))
          await this.getRobotInfo()
          // await this.getRobotList()
          for (let i=0; i<this.elementList.length; i++){
            if (this.elementList[i].index_id == this.robotIndex){
              if (this.elementList[i].state == 2) {
                this.elementList[i].state = 1;
              } else {
                this.elementList[i].state = 2;
              }
            }
          }
        } else {
          this.isLoginning = false
          this.$message.error(res.message)
        }
      } else {
        const res1 = await getOpenRobot(this.robot_type)
        if (res1.success) {
          this.isLoginning = false
          this.$message.success(this.$t('robot.robot')+this.$t('robot.turned_on'))
          await this.getRobotInfo()
          for (let i=0; i<this.elementList.length; i++){
            if (this.elementList[i].index_id == this.robotIndex){
              if (this.elementList[i].state == 2) {
                this.elementList[i].state = 1;
              } else {
                this.elementList[i].state = 2;
              }
            }
          }
          // await this.getRobotList()
        } else {
          this.isLoginning = false
          this.$message.warning(res1.message)
        }
      }
    },
    //获取机器人状态
    async getRobotInfo() {
      const res1 = await getRobotInfo(this.robot_type)
      if (!res1) {
        return;
      }
      if (res1.success) {
        this.robotInfo = res1.data;
        if(this.APIstatus){
          this.robotInfo.stateName = this.$t('robot.robot_state_name5')
          this.buttonName = this.$t('robot.button_start_stop_name5')
          return
        }
        if (this.robotInfo.state == '1') {
          this.robotInfo.stateName = this.$t('robot.robot_state_name1')
          this.buttonName = this.$t('robot.button_start_stop_name2')
        } else if (this.robotInfo.state == '2') {
          this.robotInfo.stateName = this.$t('robot.robot_state_name2')
          this.buttonName = this.$t('robot.button_start_stop_name1')
        } else if (this.robotInfo.state == '3') {
          this.robotInfo.stateName = this.$t('robot.robot_state_name3')
          this.buttonName = this.$t('robot.button_start_stop_name3')
        } else if (this.robotInfo.state == '4') {
          this.robotInfo.stateName = this.$t('robot.robot_state_name4')
          this.buttonName = this.$t('robot.button_start_stop_name4')
        }
        if (this.robotInfo.bind_api != 1){
          this.APIstatus = this.$t('robot.bind_api_tip')+'！'
        }
        this.nickname = this.robotInfo.robot_name?this.robotInfo.robot_name:'';
        this.setNickName = this.robotInfo.robot_name?this.robotInfo.robot_name:'';
        this.robotInfo.runTime = this.robotInfo.run_time? parseInt(this.robotInfo.run_time/24)+'天'+ parseInt(this.robotInfo.run_time%24)+ this.$t('robot.hour'):'0'
      }else {
        this.$message.warning(res1.message)
      }
    },

    //获取分布图数据
    getAssets: async function () {
      const res = await getAssets(this.robot_type)
      if (!res) return
      if (res.success) {
        // this.assetsInfo = res.data
        if (!res.data) return
        if (res.data.assets && res.data.assets != null && res.data.assets.length != 0) {
          for (let i = 0; i < res.data.assets.length; i++) {
            const obj = {}
            obj.name = res.data.assets[i].coin;
            obj.value = res.data.assets[i].percent;
            obj.num = res.data.assets[i].num?this.$options.filters['roundEightFilter'](res.data.assets[i].num):'0.00';
            this.assetsInfo.push(obj)
          }
        }
        if (res.data.total_balance){
          this.totalBalance = res.data.total_balance
        }else{
          this.totalBalance = '0'
        }
        let trendChart = this.$echarts.init(document.getElementById('chart_pie'));
        this.drawLine(trendChart);
      }
    },

    async getUserInfo() {
      const res = await getUserInfo(this.token)
      if (!res) return
      if (res.success) {
        this.userInfo = res.data
        this.chargeStyle = res.data.charge_style2
        this.value_sw = res.data.allow_view_position.toString()
        if(res.data.mobile){
          this.userPhone = res.data.mobile.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")
        }
        if (this.chargeStyle == 1) {
          this.surplusNum = 3
          this.semiSurplusNum = 8
        } else if(this.chargeStyle == 2){
          this.surplusNum = 8
          this.semiSurplusNum = 12
        } else if(this.chargeStyle == 3){
          this.surplusNum = 12
          this.semiSurplusNum = 20
        } else if(this.chargeStyle == 0) {
          this.surplusNum = 1
          this.semiSurplusNum = 3
        }
        if (this.userInfo.name == '' || this.userInfo.name == null) {
          this.userInfo.otherName = this.userInfo.username
        }
        if (this.userInfo.username == '' || this.userInfo.username == null) {
          this.userInfo.otherName = this.userInfo.mobile
        }
        // this.getSymbolCount()
      }
    },

    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=900) {
          this.lgShow = true
          return
        }
        this.lgShow=false
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=999) return this.lgShow = true
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 跳转到用户使用协议页面
    showUsageProtocol() {
      this.$router.push({name: 'UserUsageProtocol'})
    },

    changeApiInfo(items, index){
      if (index == this.robotIndex && this.APIkEY){
        return
      }
      this.APIstatus = ''
      if(items.robot_type == 0){
        this.robot_type = 0
        this.getApiInfo(items, index)
      }else if(items.robot_type == 1){
        this.robot_type = 1
        this.getOkApiInfo(items, index)
      }
      this.lishijiaoyi = 0;
      this.lishijiaoyiList_1 = [];
      this.getUserFuturesException()
    },

    changeTrade(items, index, init){
      this.elementItem = items;
      let e = 'click' + index;
      if(items.bind_api == 1 && (!init)){
        if (index == 2) {
          document.getElementById(e).style.color = '#AB54DB'
          document.getElementById('click2').style.borderBottom = '2bx'
          document.getElementById('click2').style.borderBottomColor = '#AB54DB'
          document.getElementById('click2').style.borderBottomStyle = 'solid'
          if (this.elementList.length != 1 != 0 && this.elementList.length != 1) {
            document.getElementById('click1').style.borderBottom = 'unset'
          }
        } else {
          document.getElementById(e).style.color = '#F3BA2F'
          document.getElementById('click1').style.borderBottom = '2bx'
          document.getElementById('click1').style.borderBottomColor = '#F3BA2F'
          document.getElementById('click1').style.borderBottomStyle = 'solid'
          if (this.elementList.length != 1 != 0 && this.elementList.length != 1) {
            document.getElementById('click2').style.borderBottom = 'unset'
          }
        }
      }

      getIndex(index);
      this.robotIndex = index;
      this.assetsInfo = [];
      this.initData();
      this.initData2();
    },
    async getRobotList(){
    // 获取机器人列表
      const res = await getRobotList();
      if(res){
        if (res.success){
          if(res.data){
            this.elementList = res.data
            for (let i=0; i< this.elementList.length; i++){
              this.elementList[i].robotBindState = 0;
              if(this.elementList[i].robot_type == 0){
                if(this._isMobile()){
                  this.elementList[i].value = 'Binance'
                }else{
                  this.elementList[i].value = this.$t('robot.robot_dealer_name1')
                }
                this.elementList[i].index_id = 1
              } else {
                if(this._isMobile()){
                  this.elementList[i].value = 'Okx'
                }else {
                  this.elementList[i].value = this.$t('robot.robot_dealer_name2')
                }
                this.elementList[i].index_id = 2
              }
            }
            if (this.elementList && this.elementList.length != 0) {
              this.robotInfo = this.elementList[0]
              this.robot_type = this.elementList[0].robot_type
              this.setRobotInfo()
              this.getUserFuturesException()
              getIndex(this.elementList[0].index_id);
              // this.robot_type = this.elementList[0].robot_type
              // this.initData()
              // this.initData2()
              this.elementItem = this.elementList[0]
              this.robotIndex = this.elementList[0].index_id;
              if (this.robotIndex == 1){
                await this.getApiInfo(this.elementItem,'',true, 'init')
              }else if(this.robotIndex == 2){
                await this.getOkApiInfo(this.elementItem,'',true, 'init')
              }
            }
          }
        }
      }
      this.setViewforTitle()
    },

    setRobotInfo(){
      if(this.APIstatus){
        this.robotInfo.stateName = this.$t('robot.robot_state_name5')
        this.buttonName = this.$t('robot.button_start_stop_name5')
        return
      }
      if (this.robotInfo.state == '1') {
        this.robotInfo.stateName = this.$t('robot.robot_state_name1')
        this.buttonName = this.$t('robot.button_start_stop_name2')
      } else if (this.robotInfo.state == '2') {
        this.robotInfo.stateName = this.$t('robot.robot_state_name2')
        this.buttonName = this.$t('robot.button_start_stop_name1')
      } else if (this.robotInfo.state == '3') {
        this.robotInfo.stateName = this.$t('robot.robot_state_name3')
        this.buttonName = this.$t('robot.button_start_stop_name3')
      } else if (this.robotInfo.state == '4') {
        this.robotInfo.stateName = this.$t('robot.robot_state_name4')
        this.buttonName = this.$t('robot.button_start_stop_name4')
      }
      if (this.robotInfo.bind_api != 1){
        this.APIstatus = this.$t('robot.bind_api_tip')+'！'
      }
      this.nickname = this.robotInfo.robot_name?this.robotInfo.robot_name:'';
      this.setNickName = this.robotInfo.robot_name?this.robotInfo.robot_name:'';
    },

    setViewforTitle(){
      for (let i = 0; i < this.elementList.length; i++) {
        if (this.elementList[i].robot_type == 1) {
          if (this.elementList[i].bind_api == 1) {
            this.elementList[i].icon = icon2;
          }else{
            this.elementList[i].icon = icon4;
          }
        } else if (this.elementList[i].robot_type == 0) {
          if (this.elementList[i].bind_api == 1){
            this.elementList[i].icon = icon1;
          }else{
            this.elementList[i].icon = icon3;
          }
        }
      }
      this.timer = setTimeout(() => {
        if (this.elementList==[] || this.elementList.length == 0) return
        document.getElementById('click'+this.elementList[0].index_id).style.borderBottom = '2px'
        document.getElementById('click'+this.elementList[0].index_id).style.borderBottomStyle = 'solid'
        if (this.elementList != null && this.elementList.length  > 1){
          document.getElementById('click'+this.elementList[1].index_id).style.borderBottom = 'unset'
        }
        for (let i=0; i<this.elementList.length; i++){
          if (this.elementList[i].index_id == 1 && this.elementList[i].bind_api == 1){
            document.getElementById('click'+this.elementList[i].index_id).style.color = '#F3BA2F'
            document.getElementById('click'+this.elementList[i].index_id).style.borderBottomColor = '#F3BA2F'
          }
          if (this.elementList[i].index_id == 2 && this.elementList[i].bind_api == 1) {
            document.getElementById('click'+this.elementList[i].index_id).style.color = '#AB54DB'
            document.getElementById('click'+this.elementList[i].index_id).style.borderBottomColor = '#AB54DB'
          }
        }
        clearTimeout(this.timer)
      }, 0)
    },

    setNickNameFocus(){
      if (this.APIstatus){
        this.$message.warning(this.APIstatus)
        return
      }
      this.isShowEditNiceName = true;
      // document.getElementById('nickName').
    },
    async setNick(){
      if (!this.setNickName || this.setNickName == this.nickname){
        this.isShowEditNiceName = false
        return
      }
      const res = await saveRobotName(this.setNickName);
      if (res){
        if (res.success){
          this.nickname = this.setNickName;
          this.isShowEditNiceName = false
        }else{
          this.$message.error(res.message)
        }
      }
    },

    imgId(id, num){
      return id+num;
    },

    //获取用户的api信息
    async getApiInfo(item, index, sortNum, init){
      if (item && item.bind_api == 1) {
        this.changeTrade(item, index, init)
      } else {
        Message({
          type: "warning",
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: item?(`<a style="display: block; border-bottom: 1px solid;color: unset;width: fit-content; margin: 0px;cursor:pointer;" href="/user/safe">${this.$t('robot.bind_binance_api_tip')}！</a><br/><a style="display:inline-block;color:#AB54DB;margin-top: 0px" target="_blank" href="https://www.binancezh.top/zh-CN">Binance${this.$t('robot.register_address')}>></a>`):(`<a style="display: block; border-bottom: 1px solid;color: unset;width: fit-content; margin: 0px;cursor:pointer;" href="/user/safe">${this.$t('robot.bind_api_tip')}！</a>`)
        });
        this.APIstatus = this.$t('robot.bind_api_tip')+'！'
      }
    },

    // 获取ok_api信息
    async getOkApiInfo(item, index, sortNum, init){
      if (item && item.bind_api == 1) {
        this.changeTrade(item, index, init)
      } else {
        if (item){
          this.dialogMessageOfOkx = true
        } else {
          Message({
            type:'warning',
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: (`<a style="display: block; border-bottom: 1px solid;color: unset;width: fit-content; margin: 0px;cursor:pointer;" href="/user/safe">${this.$t('robot.bind_api_tip')}！</a>`)
          });
        }
        this.APIstatus = this.$t('robot.bind_api_tip')+'！'
      }
    },

    scrollTo(to) {
      this.timer = setTimeout(() => {
        document.documentElement.scrollTop = to;
        clearTimeout(this.timer)
      },1)
      this.scroll=0
    },

    async getUserPolicy(times){// 燃料费即将到期提示
      const res = await getUserPolicy(times)
      if (res){
        if (res.success){
          if (!res.data) return
          this.activity = res.data.activity
          var date = res.data.update_time
          date = date.replaceAll('-', '/')
          this.upDateTime = this.$util.formatDate_c(date, '.');
          this.showUpDate = new Date(date).getTime()
        }else{
          this.$message.warning(res.message)
        }
      }
    },

    gotoSafe() {
      this.$router.push('/user/safe')
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },

    noviceShow(){
      this.isNoviceShow = false
      localStorage.setItem('isNoviceShow', 'false')
    },

    getUserFuturesException(hideLoad){
      getUserFuturesException(this.robot_type, hideLoad).then(res=>{
        if(!res) return
        if(res.success){
          if(res.success){
            this.futuresException = res.data
            if(this.futuresException.exceptions.length <= 1) {
              for (var i = 0; i < this.futuresException.exceptions.length; i++) {
                this.linshiException = this.futuresException.exceptions[i]
              }
            }
          } else {
            this.$message.warning(res.message)
          }
        }
      })
    },

    showWarnPop(){
      this.percent = this.futuresException.scores
      this.warnList = this.futuresException.exceptions

      for (var i = 0; i < this.warnList.length; i++) {
        // if (this.warnList[i].errorType == 3) {
        //   this.warnList.splice(i, 1)
        // }
        if (this.warnList[i].errorType == 5) {
          this.warnList.splice(i, 1)
        }
      }

      for (var i = 0; i < this.warnList.length; i++) {
        if(this.warnList[i].errorType == 1){
          this.warnList[i].errorTypeName = this.$t('footer.ul_li1')
        } else if(this.warnList[i].errorType == 2) {
          this.warnList[i].errorTypeName = this.$t('robot.add_position_long')
        } else if(this.warnList[i].errorType == 3){
          this.warnList[i].errorTypeName = this.$t('robot.fill_up_positions')
        } else if(this.warnList[i].errorType == 4){
          this.warnList[i].errorTypeName = this.$t('robot.add_position') + 'API'
        } else if(this.warnList[i].errorType == 5) {
          this.warnList[i].errorTypeName = this.$t('footer.ul_li1')
        } else if(this.warnList[i].errorType == 6){
          this.warnList[i].errorTypeName = this.$t('robot.Recharge')
        }
      }
      this.warnDialog = true
    },

    warnMenu(item){
      //errorType错误类型：1资金不足，2仓位不足，3亏损大于50%，4API无效，5API没有划转权限，6燃料费不足
      if(item.errorType == 1){
        if (this._isMobile()){
          this.$router.push('/messageBack')
        } else {
          this.$store.state.showFeedBack = true
        }
      } else if(item.errorType == 2) {

      } else if(item.errorType == 3){
        this.warnFillUpPositions = true
        this.warnFillUpItem = item
        this.availableAmount = ''
      } else if(item.errorType == 4){
        this.$router.push('./user/safe')
      } else if(item.errorType == 5) {
        if (this._isMobile()){
          this.$router.push('/messageBack')
        } else {
          this.$store.state.showFeedBack = true
        }
      } else if(item.errorType == 6){
        this.$router.push('./user/property')
      }
      this.warnDialog = false
    },

    setAll(){
      this.availableAmount = parseFloat(this.warnFillUpItem.futuresInfo.canFill)
    },

    confirmAvailableAmount(){
      console.log('asdqe12e3qweqew', this.availableAmount)
      if(!this.availableAmount){
        this.$message.warning('请输入要补齐的仓位')
        return
      }
      complementFuturesSpace(this.warnFillUpItem.futuresInfo.robotType, this.warnFillUpItem.futuresInfo.sid, this.availableAmount).then(res=>{
        if(!res) return
        if(res.success){
          this.$message.success('修改完成！')
          this.warnFillUpPositions = false
          this.getUserFuturesException()
        } else {
          this.$message.error(res.message)
        }
      })
    }

  },

  deactivated() {
    clearInterval(this.exceptionInterval)
    this.exceptionInterval = null
  },

  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector('input').focus()
      }
    }
  },

  echartPie() {

  },

  components: {
    Tooltip,
    Pop_image,
    Robot_strategy,
    draggable
  }
}
</script>

<style scoped>
.draggable{
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  overflow: hidden;
  color: #303133;
  transition: .3s;
  margin-bottom: 24px;
  padding: 0 20px;
}

.dragElement{
  cursor: pointer;
  padding: 20px 0;
  color: #72777E;
  font-size: 16px;
}

/*********         机器人收益        *************/
.robot-earn .el-card__body > div:first-child {
  font-size: 16px;
  color: #1E2329;
  font-weight: 600;
}

.robot-earn div:nth-child(2) {
  font-size: 14px;
  color: #72777E;
  margin: 24px 0 10px 0;
}

.robot-earn div:nth-child(3) {
  font-size: 30px;
  color: #000613;
  display: inline-block;
  margin: 0 30px 0px 0;
  vertical-align: top;
}

.robot-earn div:nth-child(4) {
  display: inline-block;
  vertical-align: top;
}

.robot-earn div:nth-child(4) div:first-child {
  font-size: 20px;
  color: #72777E;
  display: inline-block;
}

.robot-earn div:nth-child(4) div:nth-child(2) {
  display: inline-block;
  font-size: 20px;
  line-height: 40px;
  margin: 0 0 0 6px;
  color: #000613;
}

.robot-earn div:nth-child(5) {
  font-size: 12px;
  color: #72777E;
  margin-bottom: 24px;
}

.robot-earn div:nth-child(5) div:nth-child(1) {
  font-size: 12px;
  color: #72777E;
  display: inline-block;
}

.robot-earn div:nth-child(5) div:nth-child(2) {
  display: inline-block;
  margin: 0 0 0 40px;
  font-size: 12px;
  color: #72777E;
}

.robot-earn .trend, .robot-earn .rank {
  display: inline-block;
  width: 49%;
}

.robot-earn .trend .trend-title, .robot-earn .rank .rank-title {
  font-size: 14px;
  color: #72777E;
}

.trend button {
  background-color: white;
  color: #FF4D4D;
  width: 140px;
  font-size: 18px;
  height: 50px;
  border: 1px #FF4D4D solid;
  border-radius: 4px;
  display: block;
  margin-top: 15px;
}

/************       每日收益           ************/
.everyday-top {
  margin-bottom: 20px;
  position: relative;
}

.everyday-earn .el-card__body .everyday-top > div {
  display: inline-block;
}

.everyday-earn .title {
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}

.everyday-earn .title-sub {
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-left: 16px;
}

.el-table__body, .el-table__footer, .el-table__header {
  color: #4e71f7 !important;
}

.everyday-top .tabs {
  position: relative;
  top: 0;
  display: flex !important;
  width: 200px;
  justify-content: space-between;
}

.everyday-top .tabs > div {
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  cursor: pointer;
}

.everyday-top .tabs > div:first-child {
  color: #AB54DB;
}

/*************    收益查询      ***********/
.earn-query .earn-query-top {
  position: relative;
}

.earn-query-top .title {
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}

.earn-query-top > .date-picker {
  position: absolute;
  top: -4px;
  right: 0;
}

.robot-msg span {
  font-size: 16px;
}

.robot-add-name {
  display: block;
  color: #1E2329;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 20px;
}

.robot-add-des {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
  margin-top: 25px;
  margin-left: 20px;
}

.robot-add-name-add {
  display: block;
  color: #1E2329;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.robot-add-des-add {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-top: 5px;
  margin-left: 20px;
}

/*.robot-add-btn {*/
/*  display: block;*/
/*  color: #AB54DB;*/
/*  border-radius: 2px;*/
/*  padding: 6px 20px;*/
/*  background: none;*/
/*  border: 1px solid #AB54DB;*/
/*  margin: 40px auto 0;*/
/*}*/

.robot-add-btn {
  display: block;
  color: #AB54DB;
  border-radius: 2px;
  padding: 6px 20px;
  background: none;
  border: 1px solid #AB54DB;
  margin: 25px auto 0;
  font-size: 14px;
}
.robot-add-btn-sj {
  display: block;
  color: #E3B936;
  border-radius: 2px;
  padding: 6px 20px;
  background: none;
  border: 1px solid #E3B936;
  margin: 25px auto 0;
  font-size: 14px;
}

.table tr td {
  text-align: center;
  /*text-align: justify;*/
  /*text-justify: distribute-all-lines;*/
  /*text-align-last: justify;*/
}

.tc {
  padding: 12px 0;
  line-height: 23px;
  width: 25%;
  color: #E3B936;
  font-size: 14px;
}

.tl {
  padding: 12px 0;
  line-height: 23px;
  width: 25%;
  color: #E3B936;
  font-size: 14px;
  text-align: left;
}

.tr {
  padding: 14px 0;
  line-height: 23px;
  width: 25%;
  color: #E3B936;
  font-size: 14px;
  text-align: right;
}

.th {
  font-size: 14px;
  color: #72777E;
  padding-left: 5px;
}

.td{
  padding: 5px 0px;
  line-height: unset;
}

.table tr td {
  text-align: center;
  /*text-align: justify;*/
  /*text-justify: distribute-all-lines;*/
  /*text-align-last: justify;*/
}

.sp-coin-name {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
}


@media screen and (max-width: 900px) {
  .draggable{
    margin-bottom: 12px;
  }

  .rank_div /deep/ .el-card__body {
    display: block;
    padding: 12px 0  !important;
    margin: 0 0px !important;
    border: none !important;
    border-bottom: 1px solid #EAECEF !important;
  }

  .robot-earn {
    display: none;
  }

  .robot-pc {
    display: none;
  }

  .robot-msg {
    padding: 20px 0;
    border-bottom: #EAECEF 1px solid;
  }

  .trend button {
    background-color: white;
    color: #FF4D4D;
    width: 100%;
    font-size: 14px;
    height: 40px;
    border: #FF4D4D 1px solid;
    border-radius: 4px;
    display: block;
    font-weight: 400;
    margin-top: 15px;
  }

  .everyday-earn .title-sub {
    font-size: 14px;
    font-weight: 400;
    color: #72777E;
    margin-top: 6px;
    margin-left: 0px;
  }

  .shouyi_div {
    width: 100%;
  }

  .dialog-mb {
    margin: -40px auto 0px;
  }

  .dialog-mb-ub {
    margin: 0 auto;
  }

  .add_jiaoyidui {
    margin-left:30px;
    text-align: left;
  }

  .sj-svip-id{
    width: 50px;
    margin-top: -1px;
    margin-right: -1px;
  }

  .sj-line{
    margin: 10px -85px -15px 0px;
  }

  .sj-sp-coin-name{
    width: 70%;
  }

  .dragElement{
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 20px 0;
    color: #72777E;
    font-size: 16px;
  }

  .all_content{
    min-width: unset;
  }
  .rank-deal{
    width: 200px;
  }
}

@media screen and (max-width: 1400px) {
  .rank-deal{
    width: 200px;
  }
}

@media screen and (max-width: 1450px) {
  .rank-deal{
    width: 280px;
  }
}

@media screen and (min-width: 900px) {
  .robot-earn-mb {
    display: none;
  }

  .robot-earn {
    display: block;
  }

  .shouyi_div {
    width: 32%;

  }

  .shouyi_div2 {
    margin: 0 2%;
  }

  .robot-pc {
    display: block;
  }

  .dialog-mb {
    margin: -95px auto 0px;
  }

  .dialog-mb-ub {
    margin: 0 auto;
  }

  .add_jiaoyidui {
    margin-left:20px;
    text-align: left;
  }

  .sj-svip-id{
    width: 60px;
    margin-top: -1px;
    margin-right: -1px;
  }

  .sj-line {
    margin: 10px -110px -15px 0px;
  }

  .sj-sp-coin-name{
    width: 83%;
  }
  .dragElement:nth-child(2) {
    margin-left: 30px;
  }

  .all_content{
    min-width: 1060px;
  }
}

.side_color_red {
  color: #FF4D4D
}

.side_color_green {
  color: #52C41A
}

.coin_color_deep{
  color: #000000;
}

.coin_color_light{
  color: #C0C4C8;
}

.check_cangwei {
  color: #AB54DB;
  font-size: 14px;
  width: max-content;
  cursor: pointer
}
.uncheck_cangwei {
  color: #72777E;
  font-size: 14px;
  width: max-content;
  cursor: pointer
}

.listStyle1{
  border-bottom: 2px #F3BA2F solid;
  display: inline-block;
}
.listStyle2{
  border-bottom: 2px #AB54DB solid;
  display: inline-block;
}

.listStyle3{
  border-bottom: unset;
}

.listStyle4{
  border-bottom: unset;
}

 /deep/.el-card__body {
  border: none !important;
}

>>>.el-tabs__nav-wrap::after{
  height: 1px;
}

>>>.el-select-dropdown__item{
  height: 45px;
  line-height: 45px;
}

>>>.el-tabs__item.is-active {
  color: #AB54DB;
}

.rank-deal >>>.el-tabs__item{
  padding: 0 12px;
}

>>>.el-tabs__active-bar{
  background-color: #AB54DB;
}

.dialog-space >>>.el-dialog{
  min-width: 410px;
}

@-webkit-keyframes rotation{
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}

/*.dragElement img{*/
/*  -webkit-transform: rotate(360deg);*/
/*  animation: rotation 3s linear infinite;*/
/*  -moz-animation: rotation 3s linear infinite;*/
/*  -webkit-animation: rotation 3s linear infinite;*/
/*  -o-animation: rotation 3s linear infinite;*/
/*}*/

.img_rotate{
  -webkit-transform: rotate(360deg);
  animation: rotation 3s linear infinite;
  -moz-animation: rotation 3s linear infinite;
  -webkit-animation: rotation 3s linear infinite;
  -o-animation: rotation 3s linear infinite;
}

.img_unrotate{

}

.go_and_open{
  font-weight: 400;
  font-size: 14px;
  color: white;
  background-color: #AB54DB;
}

.set-nick {
  margin: 0px !important;
  width: 80px;
  vertical-align: middle !important;
}

.set-nick >>> .el-input__inner {
  height: 30px;
  padding: 5px 0px;
  font-size: 16px;
  vertical-align: middle;
  display: inline-block;
  border: none;
}
.set-nick >>>.el-input{
  width: 100px;
}

.robot_btn {
  color: #C0C4CC !important;
  border: 1px #EBEEF5 solid !important;
}

.rank_num_color_y {
  color: #E3B936;
  font-size: 14px;
  font-weight: 600;
}

.rank_num_color_ {
  color: #000613;
  font-size: 14px;
}

.robot_name_sp {
  color: gray;
  vertical-align: middle;
  display: inline-block;
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.run_status{
  color: #52C41A;
  display: inline-block;
  vertical-align: middle
}

.stop_status {
  color: #FF4D4F;
  display: inline-block;
  vertical-align: middle
}
.sleep_status {
  color: #72777E;
  display: inline-block;
  vertical-align: middle
}

/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

>>>.el-table .cell{
  padding-right: 5px;
  padding-left: 5px;
}
.novice_show_divider{
  margin: 0;
}
.novice_show >>>.el-dialog__body{
  /*padding-bottom: 60px;*/
  /*top: 150px !important;*/
  /*left: 20px !important;*/
  /*width: 89.5% !important;*/
  padding: 10px 20px 20px;
}

.warnning{
  width: 35px;
  height: 31px;
  position: absolute;
  top: 0px;
  margin-left: -30px;
  cursor: pointer;
  -webkit-animation: twinkling 0.5s infinite 0.5s ease-in-out alternate;
}

@-webkit-keyframes twinkling{    /*透明度由0到1*/
  0%{
    opacity:0; /*透明度为0*/
  }
  100%{
    opacity:1; /*透明度为1*/
  }
}

>>>.el-progress__text{
  font-size: 14px !important;
}
</style>
