import vue from "../../../main";
export default {
        trading_start:vue.$t('martinComments.trading_start'),
        policyTypeValue: vue.$t('martinComments.policyTypeValue'),
        fullSpace: vue.$t('martinComments.fullSpace'),
        trendPeriod: vue.$t('martinComments.trendPeriod'),
        firstOrderMoney: vue.$t('martinComments.firstOrderMoney'),
        cycleIndex: vue.$t('martinComments.cycleIndex'),
        firstOrderTimes: vue.$t('martinComments.firstOrderTimes'),
        orderNumber: vue.$t('martinComments.orderNumber'),
        stopProfitRatio: vue.$t('martinComments.stopProfitRatio'),
        stopProfitCallback: vue.$t('martinComments.stopProfitCallback'),
        coverConfig: vue.$t('martinComments.coverConfig'),
        coverCallback: vue.$t('martinComments.coverCallback'),
        fullOrderStopLoss: vue.$t('martinComments.fullOrderStopLoss'),
        stopLossRatio: vue.$t('martinComments.stopLossRatio'),
}