<template>
  <div>
    <el-tooltip class="item" effect="dark" :content="martinComments.trading_start" placement="top">
      <div class="label-div">{{$t('detail_param.select_trading_on')}}</div>
    </el-tooltip>
    <div style="display: flex;justify-content: space-between;align-items: center">
      <el-select class="coin_one" :popper-append-to-body="false" :disabled="isAddMartin==3" v-model="trading_start_value" filterable :placeholder="$t('detail_param.please_select')">
        <el-option
            v-for="item in trading_start"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <div style="margin: 0 6px"><i style="color:#AB54DB;transform: rotate(-90deg) rotateY(180deg);" class="el-icon-sort-down"></i></div>
      <el-select class="coins" :popper-append-to-body="false" @change="changeCoin" :disabled="isAddMartin==3" v-model="trading_end_value" filterable :placeholder="$t('detail_param.please_select')" ref='select1' @focus="closeReadOnly($event,1)" @hook:mounted="closeReadOnly($event,1)" @visible-change="closeReadOnly($event,1)" >
        <el-option
            :key="change_url=='robot'? item.coin_name : item.target_coin+' / '+item.cost_coin"
            v-for="(item, index) in trading_end"
            :value="change_url=='robot'? item.coin_name : item.target_coin">
        </el-option>
      </el-select>
    </div>
    <!--          策略類型-->
    <div>
      <div class="label-div">
        <el-tooltip class="item" effect="dark" placement="top">
          <div slot="content" class="comment-tip" v-html="martinComments.policyTypeValue"></div>
        <div class="title" style="display: inline-flex;align-items: center">{{$t('detail_param.select_strategy_type')}}</div>
        </el-tooltip>
      </div>
      <el-select class="strategy_type" :popper-append-to-body="false" :disabled="isAddMartin==3" @change="handleChangeCoverConfig('policyTypeValue')" v-model="policyTypeValue" :placeholder="$t('detail_param.please_select')" style="width: 100%">
        <el-option
            v-for="item in policyType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <!--          參數詳情-->
    <div @click="handleCutDetail" style="cursor: pointer;margin: 17px 0 17px 0;display: flex;justify-content: space-between;align-items: center"><span>{{$t('detail_param.param_detail')}}</span>
<!--      <i style="color:#C0C4CC;" class="el-icon-arrow-down" :class="{'isShowDown':!showDetail,'isShowUp':showDetail}"></i>-->
      <div>
        <i style="color:#AB54DB;" :class="{'el-icon-s-unfold':!showDetail,'el-icon-s-fold':showDetail}"></i>
        <span style="color: #AB54DB;margin-left: 2px">{{showDetail ? $t('detail_param.fold') : $t('detail_param.unfold')}}</span>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="showDetail">
        <div style="display: flex;justify-content: center;align-items: center;width: 100%">
<!--          <el-input class="alter-input" disabled placeholder="如果不會設置建議使用默認參數"></el-input>-->
          <div style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #72777E;background: #F7F9FC;font-size: 14px">{{$t('detail_param.not_advice')}}</div>
          <div @click="handleChangeCoverConfig('policyTypeValue')" style="margin-left: 8px;border-radius: 2px;color: #AB54DB;font-size: 12px;text-align: center;width: 96px;min-width: 96px;height: 30px;padding: 0 4px;line-height: 32px;border: #AB54DB solid 1px;cursor: pointer">{{$t('detail_param.recover_default')}}</div>
        </div>

        <div class="detail">
          <div>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.fullSpace}}</div>
            <div>{{$t('detail_param.full_order_money')}}</div>
            </el-tooltip>
            <div style="line-height: 32px">
              <span>{{fullOrderSpace}} USDT</span>
            </div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.trendPeriod" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.trendPeriod}}</div>
              <div>{{$t('detail_param.trendPeriod')}}</div>
            </el-tooltip>
            <div v-show="!detailParams.isShowTrendPeriodInput" style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;cursor: pointer;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #f56c6c">{{$t('detail_param.closed')}}</div>
            <el-select v-show="detailParams.isShowTrendPeriodInput" :popper-append-to-body="false" class="selsectbox" @change="decideCycleEffect('trendPeriod')" v-model="detailParams.trendPeriod" :placeholder="$t('detail_param.please_select')">
              <el-option
                  v-for="item in trendPeriodTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div class="suffix"><span @click="detailParams.isShowTrendPeriodInput=!detailParams.isShowTrendPeriodInput" class="unit-blue">{{detailParams.isShowTrendPeriodInput ? $t('detail_param.close') : $t('detail_param.open')}}</span></div>
            <div v-show="detailParamsError.trendPeriod" class="error-tip">{{ $t('detail_param.please_select_trendPeriod') }}</div>
            <div v-show="cycleEffect.trendPeriod" class="warn-tip">{{$t('detail_param.alter_next_effect')}}</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.firstOrderMoney" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.firstOrderMoney}}</div>
              <div>{{$t('detail_param.firstOrderMoney')}}</div>
            </el-tooltip>
            <el-input @change="checkChangeInput($event,'firstOrderMoney')" @input="checkinput($event,'firstOrderMoney',2);checkInputError($event,'firstOrderMoney');decideCycleEffect('firstOrderMoney')" v-model="detailParams.firstOrderMoney" class="alter-input"></el-input>
            <div class="suffix unit-gray">USDT</div>
            <div v-show="detailParamsError.firstOrderMoney" class="error-tip">{{$t('detail_param.least_deal')}}{{detailParamsError.firstOrderMoneyContent}}USDT</div>
            <div v-show="cycleEffect.firstOrderMoney" class="warn-tip">{{$t('detail_param.alter_next_effect')}}</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.firstOrderTimes" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.firstOrderTimes}}</div>
              <div>{{$t('detail_param.firstOrderTimes')}}</div>
            </el-tooltip>
            <el-input @change="checkChangeInput($event,'firstOrderTimes')" @input="checkinput($event,'firstOrderTimes',2);decideCycleEffect('firstOrderTimes')"  v-model="detailParams.firstOrderTimes" class="alter-input"></el-input>
            <div class="suffix unit-gray">倍</div>
            <div v-show="detailParamsError.firstOrderTimes" class="error-tip">{{ $t('detail_param.please_input_firstOrderTimes') }}</div>
            <div v-show="cycleEffect.firstOrderTimes" class="warn-tip">{{$t('detail_param.alter_next_effect')}}</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.cycleIndex" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.cycleIndex}}</div>
              <div>{{$t('detail_param.cycleIndex')}}</div>
            </el-tooltip>
            <div v-show="detailParams.cycleIndex==0 &&detailParams.cycleIndex!==''" style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;cursor: pointer;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #606266">
              {{ $t('detail_param.infinite') }}</div>
            <el-input v-show="detailParams.cycleIndex!=0 || detailParams.cycleIndex===''" @change="checkChangeInput($event,'cycleIndex')" @input="checkinput($event,'cycleIndex',1);decideCycleEffect('cycleIndex')" v-model="detailParams.cycleIndex" class="alter-input"></el-input>
            <div class="suffix"><span class="unit-gray">次</span><span @click="detailParams.cycleIndex=='0' ? detailParams.cycleIndex='' : detailParams.cycleIndex='0'" class="unit-blue">{{detailParams.cycleIndex=='0' ? $t('detail_param.limited') : $t('detail_param.infinite')}}</span></div>
            <div v-show="detailParamsError.cycleIndex" class="error-tip">{{ $t('detail_param.please_input_cycleIndex') }}</div>
            <div v-show="cycleEffect.cycleIndex" class="warn-tip">{{$t('detail_param.alter_next_effect')}}</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.orderNumber" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.orderNumber}}</div>
              <div>{{$t('detail_param.orderNumber')}}</div>
            </el-tooltip>
            <el-input @change="checkChangeInput($event,'orderNumber')" @input="checkinput($event,'orderNumber',1);decideCycleEffect('orderNumber')" @blur="handleChangeCoverConfig('orderNumber')" v-model="detailParams.orderNumber" class="alter-input"></el-input>
            <div class="suffix unit-gray">{{$t('detail_param.times')}}</div>
            <div v-show="detailParamsError.orderNumber" class="error-tip">{{ $t('detail_param.please_input_orderNumber') }}</div>
            <div v-show="cycleEffect.orderNumber" class="warn-tip">{{$t('detail_param.alter_next_effect')}}</div>
          </div>


          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.stopProfitRatio" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.stopProfitRatio}}</div>
              <div>{{$t('detail_param.stopProfitRatio')}}</div>
            </el-tooltip>
            <el-input @change="checkChangeInput($event,'stopProfitRatio')" @input="checkinput($event,'stopProfitRatio',3)" v-model="detailParams.stopProfitRatio" class="alter-input"></el-input>
            <div class="suffix unit-gray">%</div>
            <div v-show="detailParamsError.stopProfitRatio" class="error-tip">請輸入止盈比例</div>
          </div>

          <div class="user-change2">
            <el-tooltip class="item" effect="dark" :content="martinComments.stopProfitCallback" placement="top">
          <div slot="content" class="comment-tip">{{martinComments.stopProfitCallback}}</div>
                      <div>{{$t('detail_param.stopProfitCallback')}}</div>
            </el-tooltip>
            <el-tooltip effect="dark" :content="$t('validate.be_about_to_pop')" placement="top">
            <div class="user-change3 user-change4" v-show="!isShowStopProfitCallbackInput" style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;cursor: pointer;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #f56c6c">{{$t('detail_param.closed')}}</div>
            </el-tooltip>
              <el-input @change="checkChangeInput($event,'stopProfitCallback')" @input="checkinput($event,'stopProfitCallback',3);checkInputError($event,'stopProfitCallback')" v-show="isShowStopProfitCallbackInput" v-model="detailParams.stopProfitCallback" class="alter-input"></el-input>
            <div class="suffix user-change user-change3"><span class="unit-gray">%</span><span @click="isShowStopProfitCallbackInput ? detailParams.stopProfitCallback=='' ? $message.warning($t('detail_param.stopProfitCallback_tip')) : isShowStopProfitCallbackInput=!isShowStopProfitCallbackInput  :isShowStopProfitCallbackInput=!isShowStopProfitCallbackInput" class="unit-blue">{{isShowStopProfitCallbackInput ? $t('detail_param.close') : $t('detail_param.open')}}</span></div>
            <div v-show="detailParamsError.stopProfitCallback" style="color: #E6A23C" class="error-tip">{{$t('detail_param.stopProfitCallback_tip2')}}</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.coverConfig" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.coverConfig}}</div>
              <div>{{$t('detail_param.coverConfig')}}</div>
            </el-tooltip>
            <div @click="openCoverDialog(1)" style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;cursor: pointer;line-height: 32px;padding-left: 15px;box-sizing: border-box;color: #AB54DB">{{detailParams.coverConfig ? $t('detail_param.have_set') : $t('detail_param.not_have_set')}} <i style="font-size: 16px;line-height: 32px" class="el-icon-caret-right"></i></div>
<!--            <div class="suffix"><span class="unit-gray"><i class="el-icon-arrow-right"></i></span></div>-->
            <div v-show="detailParamsError.coverConfig" class="error-tip">請輸入補倉配置</div>
          </div>



          <div class="user-change2">
            <el-tooltip class="item" effect="dark" :content="martinComments.coverCallback" placement="top">
          <div slot="content" class="comment-tip">{{martinComments.coverCallback}}</div>
                      <div>{{$t('detail_param.coverCallback')}}</div>
            </el-tooltip>
            <el-tooltip effect="dark" :content="$t('validate.be_about_to_pop')" placement="top">
            <div class="user-change3  user-change4" @click="openCoverDialog(2)" style="width: 100%;height: 32px;border: 1px solid #DCDFE6;border-radius: 4px;cursor: pointer;line-height: 32px;padding-left: 15px;box-sizing: border-box" :style="'color:'+(detailParams.coverCallback ? '#AB54DB' :'#f56c6c') ">{{detailParams.coverCallback ? $t('detail_param.have_set') :$t('detail_param.closed') }}  <i style="font-size: 16px;line-height: 32px" class="el-icon-caret-right"></i></div>
            </el-tooltip>
            <div class="suffix user-change3 user-change"><span @click="detailParams.coverCallback=!detailParams.coverCallback" class="unit-blue">{{detailParams.coverCallback ? $t('detail_param.close') : $t('detail_param.open')}}</span></div>
              <div v-show="detailParamsError.coverCallback" class="error-tip">請輸入追踪補倉回調</div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.fullOrderStopLoss" placement="top">
<!--              <div slot="content" class="comment-tip">{{martinComments.fullOrderStopLoss}}</div>-->
              <div>{{$t('detail_param.fullOrderStopLoss')}}</div>
            </el-tooltip>
            <div style="line-height: 32px">
              <span @click="detailParams.fullOrderStopLoss=false" style="line-height: 20px;margin-right: 10px;cursor: pointer" :style="'color:'+(!detailParams.fullOrderStopLoss ? '#FF4D4D' : '#72777E' )">{{$t('detail_param.close')}}</span>
              <el-switch
                  v-model="detailParams.fullOrderStopLoss">
              </el-switch>
              <span @click="detailParams.fullOrderStopLoss=true" style="line-height: 20px;margin-left: 10px;cursor: pointer" :style="'color:'+(detailParams.fullOrderStopLoss ? 'rgb(82, 196, 26)' : '#72777E' )">{{$t('detail_param.open')}}</span>
            </div>
          </div>

          <div>
            <el-tooltip class="item" effect="dark" :content="martinComments.stopLossRatio" placement="top">
              <div slot="content" class="comment-tip">{{martinComments.stopLossRatio}}</div>
              <div>{{$t('detail_param.stopLossRatio')}}</div>
            </el-tooltip>
            <el-input @change="checkChangeInput($event,'stopLossRatio')" @input="checkinput($event,'stopLossRatio',3);checkInputError($event,'stopLossRatio')" v-model="detailParams.stopLossRatio" class="alter-input"></el-input>
            <div class="suffix unit-gray">%</div>
            <div v-show="detailParamsError.stopLossRatio" class="error-tip">請輸入止損比例</div>
            <div v-show="detailParamsError.stopLossRatio" style="color: #E6A23C" class="error-tip">满单止损比例为:</div>
          </div>

        </div>
      </div>
    </el-collapse-transition>
    <el-dialog
        append-to-body
        :show-close="false"
        width="280px"
        class="tipDialog"
        :visible.sync="ClickTipDialogVisible">
      <span style="padding: 30px 20px;display: block;line-height: 24px" v-html="$t('detail_param.alter_dialog_tip')"></span>
      <div style="width: 100%;display: flex;justify-content: space-between">
        <el-button @click="detailParams.orderNumber=confirmSaveAlterOrderNum;ClickTipDialogVisible=false" style="width: 45%">{{$t('detail_param.cancel')}}</el-button>
        <el-button type="primary" style="width: 45%"  @click="ClickTipDialogVisibleConfirm">{{$t('detail_param.confirm')}}</el-button>
      </div>
    </el-dialog>
    <dialog_tip ref="dialogTip"  :content="'我是提示'"></dialog_tip>
    <dialog_tip ref="dialogTipKey"  :content="alterKeyTipContent"></dialog_tip>
  </div>
</template>

<script>
import tooltip from "../../common/tooltip";
import dialog_tip from "../../common/dialog_tip";
import {dialogTipMixin} from "../../../mixin/mixin";
import {screenSize} from "../../../utils/tools";
import CoverConfig from "./CoverConfig";
import {change_url, getAllCoins, getCoinBalance} from "../../../server/robot";
import martinDefaultParam from "./martinDefaultParam";
import {getCoinInfo, userAlterMartin, userCreateMartin} from "../../../server/martin";
import martinDefaultParamOkex from "./martinDefaultParamOkex";
import martinComments from "./martinComments";
export default {
  name: "Detail_param",
  data(){
    return{
      lgShow:true,
      showDetail:false,
      isShowDetailDialog:false,
      isAddMartin:1,
      isAlterCrux:false,    //是否變化為默認參數(修改策略週期 趨勢週期 做單數量)
      alterKeyTipContent:'',
      change_url:'robot',
      ClickTipDialogVisible:false,
      confirmSaveAlterOrderNum:'',
      cireTipContent:'',    //循环作用于哪次循环的提示
      //  策略交易對
      trading_start:[{
        value: 'USDT',
        label: 'USDT'
      }],
      trading_end:[],
      trading_start_value:'USDT',
      trading_end_value:'',
      //策略類型
      policyType:[
        {
          value: '1',
          label: '仓位马丁策略'
        },
        // {
        //   value: '2',
        //   label: '盈利预期马丁策略'
        // },
        // {
        //   value: '3',
        //   label: '仓位&盈利预期双马丁策略'
        // }
      ],
      policyTypeValue:'',
      detailParams:{
        trendPeriod:'',     //趨勢週期
        firstOrderMoney:'',  //首單額度
        cycleIndex:'',    //循環次數
        firstOrderTimes:'',  //首單倍數
        orderNumber:'',      //做單數量
        stopProfitRatio:'',   //止盈比例
        stopProfitCallback:'',   //止盈回調
        coverConfig:true,           //補倉配置
        coverCallback:true,         //補倉回調
        fullOrderStopLoss:true, //滿單止損
        stopLossRatio:'',        //止損比例
        isShowTrendPeriodInput:true,  //是否开启趋势周期
      },
      //  趨勢週期類型
      trendPeriodTypes:[
        {
          value: '0',
          label: '1'+this.$t('detail_param.minute')
        },{
        value: '1',
        label: '5'+this.$t('detail_param.minute')
      }, {
        value: '2',
        label: '15'+this.$t('detail_param.minute')
      },{
        value: '3',
        label: '30'+this.$t('detail_param.minute')
      }],
      detailParamsError:{
        trendPeriod:false,     //趨勢週期
        firstOrderMoney:false,  //首單額度
        firstOrderMoneyContent:'',
        cycleIndex:false,    //循環次數
        firstOrderTimes:false,  //首單倍數
        orderNumber:false,      //做單數量
        stopProfitRatio:false,   //止盈比例
        stopProfitCallback:false,   //止盈回調
        coverConfig:false,           //補倉配置
        coverCallback:false,         //補倉回調
        fullOrderStopLoss:false, //滿單止損
        stopLossRatio:false,        //止損比例
        isShowTrendPeriodInput:true,  //是否开启趋势周期
      },
      isShowStopProfitCallbackInput:false,
      cycleEffect: {
        trendPeriod: false,     //趨勢週期
        firstOrderMoney: false,  //首單額度
        cycleIndex: false,    //循環次數
        firstOrderTimes: false,  //首單倍數
        orderNumber: false,      //做單數量
      },
      martinComments:martinComments
    }
  },
  components:{tooltip,dialog_tip,CoverConfig},
  mixins:[dialogTipMixin],
  props:['coverConfig','coverCallback'],
  created() {
    this.change_url =sessionStorage.getItem('robotType')==0 ? 'robot' :'okex'
    this.lgShow=screenSize(900)
  },
  mounted() {
    console.log(martinComments);
  },
  computed:{
    fullOrderSpace(){
      if (this.coverConfig.length>=0) {
        // let fullMoneySub=0
        // for (let i=1 ; i<=this.coverConfig.length ; i++){
        //   let middlemoney=1
        //    for (let j=0 ; j<i ; j++){
        //      middlemoney =middlemoney*  this.coverConfig[j].multiple
        //    }
        //    middlemoney=middlemoney*this.detailParams.firstOrderMoney
        //   fullMoneySub=fullMoneySub+ middlemoney
        // }
        // return (fullMoneySub+this.detailParams.firstOrderMoney*this.detailParams.firstOrderTimes).toFixed(2)
        let fullMoney=0
        this.coverConfig.map(item=>{
          fullMoney=item.multiple*this.detailParams.firstOrderMoney+fullMoney
        })
        return (fullMoney+this.detailParams.firstOrderMoney*this.detailParams.firstOrderTimes).toFixed(2)
      }
      return 0

    }
  },
  methods:{
    /**
     * 恢复交易设置的修改参数
     */
    recoverAlterParam(){
      if (this.$store.state.addAlterMartin==1)  this.isAddMartin=1
      else {
        if (sessionStorage.getItem('martin')==2) this.isAddMartin=2
        else this.isAddMartin=3
        const {coin,strategy_type,trend_cycle,space,first_single_multiple,number_of_cycles,billing_quantity,stop_ratio,interference_stop_callback,tracking_stop,track_replenishment,full_stop,stop_loss_ratio,trend_cycle_switch}=this.$store.state.addAlterMartin
        this.trading_start_value=coin.slice(coin.indexOf('-')+1)
        this.trading_end_value=coin.slice(0,coin.indexOf('-'))
        this.policyTypeValue=(Number(strategy_type)+1).toString()
        if (trend_cycle=='5') this.detailParams.trendPeriod='1'
        else if (trend_cycle=='1') this.detailParams.trendPeriod='0'
        else if (trend_cycle=='15') this.detailParams.trendPeriod='2'
        else if (trend_cycle=='30') this.detailParams.trendPeriod='3'
        this.detailParams.firstOrderMoney=space
        this.detailParams.firstOrderTimes=first_single_multiple
        this.detailParams.cycleIndex=number_of_cycles
        this.detailParams.orderNumber=billing_quantity-1
        this.detailParams.stopProfitRatio=stop_ratio
        this.detailParams.stopProfitCallback=interference_stop_callback
        this.isShowStopProfitCallbackInput=tracking_stop==0 ? true : false
        this.detailParams.coverCallback=track_replenishment==0 ? true :false
        this.detailParams.fullOrderStopLoss=full_stop==0 ? true : false
        this.detailParams.stopLossRatio=stop_loss_ratio
        this.detailParams.isShowTrendPeriodInput=trend_cycle_switch==0 ? true :false
      }
    },
    ClickTipDialogVisibleConfirm(){
        this.confirmSaveAlterOrderNum=this.detailParams.orderNumber
      this.$emit('changeCoverConfig', this.policyTypeValue, this.detailParams.trendPeriod, this.detailParams.orderNumber) //只修改补仓配置和补仓回调的参数详情
      return this.ClickTipDialogVisible=false
    },
    // 修改驅逐週期 策略类型 所有的配置信息都为默认
    handleChangeCoverConfig(type){
      this.isAlterCrux=true
      if (this.$store.state.alterCoverConfig==1 || this.$store.state.alterCoverCallback==1 ||this.$store.state.addAlterMartin!=1){
        //TODO  如果修改过配置信息 则弹框提示修改会恢复默认配置   未修改过则不弹框  直接修改
        if (type=='orderNumber') {
          if (this.detailParams.orderNumber==this.confirmSaveAlterOrderNum) return
            this.ClickTipDialogVisible=true ;return
        }
      }
      if (type=='orderNumber' && (this.$store.state.alterCoverConfig!=1 || this.$store.state.alterCoverCallback!=1)) {
        if (this.$store.state.addAlterMartin) this.confirmSaveAlterOrderNum=this.detailParams.orderNumber
        return this.$emit('changeCoverConfig', this.policyTypeValue, this.detailParams.trendPeriod, this.detailParams.orderNumber) //只修改补仓配置和补仓回调的参数详情
      }
      if (type=='policyTypeValue') {
        this.recoverDefaultParam(this.policyTypeValue)    //所有的参数详情都修改为默认
        return this.confirmSaveAlterOrderNum=this.detailParams.orderNumber
      }
      this.recoverDefaultParam(this.policyTypeValue,this.detailParams.trendPeriod)        //除了趋势周期所有的参数(包括補倉回調補倉配置和詳情參數除了趨勢週期)详情都修改为默认
    },
    //打开补仓配置或者补仓回调弹框 e为1 是补仓配置  2是补仓回调
    openCoverDialog(e){
      if (e==2) return
      if (this.detailParams.orderNumber=='') return this.$message.warning(this.$t('detail_param.please_input_orderNumber'))
      if (this.policyTypeValue=='') return this.$message.warning(this.$t('detail_param.please_select_strategy_type'))
      if (this.detailParams.trendPeriod=='') return this.$message.warning(this.$t('detail_param.please_select_trendPeriod'))
      this.$emit('openCoverDialog',e,this.detailParams,this.policyTypeValue)
    },

    //獲取交易對
    async getDealCoins(){
      if (sessionStorage.getItem('robotType')==0){
        const {data}=await getAllCoins()
        this.trading_end=data
        this.change_url='robot'
      }
      else {
        const {data}=await getCoinInfo('USDT')
        this.trading_end=data
        this.change_url='okex'
      }
      this.detailParamsError.firstOrderMoney = false
      this.detailParamsError.stopProfitCallback=false
    },
    //選擇交易對
    changeCoin(e){
      this.detailParamsError.firstOrderMoney = false
      this.detailParamsError.stopProfitCallback=false
      if (this.change_url=='robot'){
        for (let i in martinDefaultParam){
          martinDefaultParam[i].firstOrderMoney='15'
        }
      }
      else {
        this.checkInputError(this.trading_end_value,'firstOrderMoney')
        // this.trading_end.map(item=>{
        //   if (item.target_coin==e){
        //     if (item.min_qty_true>martinDefaultParamOkex.detailParams1.firstOrderMoney){
        //       for (let i in martinDefaultParamOkex){
        //         martinDefaultParamOkex[i].firstOrderMoney=item.min_qty_true
        //         }
        //     }
        //   }
        // })

      }
    },
    handleCutDetail(){
      if (this.trading_end_value=='') return this.$message.warning(this.$t('detail_param.please_select_trading_on'))
      if (this.policyTypeValue=='') return this.$message.warning(this.$t('detail_param.please_select_strategy_type'))
      this.showDetail=!this.showDetail
    },
  //  生成策略函数
    async createMartin(){
      // console.log(JSON.stringify(this.coverConfig));
      // console.log(JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new)); return
      // console.log(this.isAlterCrux ? JSON.stringify(this.coverConfig) : (this.$store.state.addAlterMartin != 1 && this.$store.state.alterCoverConfig == 1) ? JSON.stringify(this.coverConfig) : JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new));
      // console.log(this.isAlterCrux ? JSON.stringify(this.coverCallback) : (this.$store.state.addAlterMartin != 1 && this.$store.state.alterCoverCallback == 1) ? JSON.stringify(this.coverCallback) : JSON.stringify(this.$store.state.addAlterMartin.replenishment_callback_new)); return
      const {
        trendPeriod,
        firstOrderMoney,  //首單額度
        cycleIndex,    //循環次數
        firstOrderTimes,  //首單倍數
        orderNumber,      //做單數量
        stopProfitRatio,   //止盈比例
        stopProfitCallback,   //止盈回調
        coverConfig,           //補倉配置
        coverCallback,         //補倉回調
        fullOrderStopLoss, //滿單止損
        stopLossRatio,        //止損比例
      }=this.detailParams
      if (this.trading_start_value=='' || this.trading_end_value=='') return this.$message.warning(this.$t('detail_param.please_select_trading_on'))
      if (this.policyTypeValue=='') return this.$message.warning(this.$t('detail_param.please_select_strategy_type'))
      if (firstOrderMoney=='') return this.$message.warning(this.$t('detail_param.please_input_firstOrderMoney'))
      if (cycleIndex==='') return this.$message.warning(this.$t('detail_param.please_input_cycleIndex'))
      if (firstOrderTimes=='') return this.$message.warning(this.$t('detail_param.please_input_firstOrderTimes'))
      if (orderNumber=='') return this.$message.warning(this.$t('detail_param.please_input_firstOrderTimes'))
      if (stopProfitRatio=='') return this.$message.warning(this.$t('detail_param.please_input_stopProfitRatio'))
      if (stopProfitCallback=='') return this.$message.warning($t('detail_param.please_input_stopProfitCallback'))
      if (stopLossRatio=='') return this.$message.warning(this.$t('detail_param.please_input_stopLossRatio'))
      if (this.detailParamsError.firstOrderMoney) return  this.$message.warning(this.$t('detail_param.please_input_firstOrderMoney_error'))
      const policyTypeValue=this.policyTypeValue-1
      let trendCycle='' //趋势周期
      if (trendPeriod==1) trendCycle=5
      else if (trendPeriod==0) trendCycle=1
      else if (trendPeriod==2) trendCycle=15
      else trendCycle=30
      //当用户资金不足()
      //   const data = await getCoinBalance(this.trading_start_value,change_url=='robot' ? 0 : 1);
      //   if (!data) return;
      //   if (data.success){
      //     if (!data.data) return;
      //     var balance=data.data
      //   }
      if (this.fullOrderSpace>9999999999) return this.$message.warning(this.$t('detail_param.full_order_money_exceed_limit'))
      if (this.$parent.isSuccess != 'defined') this.$parent.isSuccess=true
      if (this.$parent.$parent.isSuccess != 'defined') this.$parent.$parent.isSuccess=true
      if (this.$store.state.addAlterMartin==1 || this.isAddMartin==2){
        var res=await userCreateMartin(
            sessionStorage.getItem('robotType'),
            this.trading_start_value,
            this.trading_end_value,
            policyTypeValue,
            trendCycle,
            firstOrderMoney,
            cycleIndex,
            firstOrderTimes,
            Number(orderNumber)+1,
            stopProfitRatio,
            stopProfitCallback,
            this.isShowStopProfitCallbackInput ?  0 : 1,
            JSON.stringify(this.coverConfig),
            JSON.stringify(this.coverCallback),
            coverCallback ? 0 :1,
            fullOrderStopLoss ? 0 :1,
            stopLossRatio,
            this.fullOrderSpace,
            this.detailParams.isShowTrendPeriodInput ? 0 : 1
        )
      }
      else {
        var res=await userAlterMartin(
            sessionStorage.getItem('robotType'),
            this.trading_start_value,
            this.trading_end_value,
            policyTypeValue,
            trendCycle,
            firstOrderMoney,
            cycleIndex,
            firstOrderTimes,
            Number(orderNumber)+1,
            stopProfitRatio,
            stopProfitCallback,
            this.isShowStopProfitCallbackInput ?  0 : 1,
            this.isAlterCrux ? JSON.stringify(this.coverConfig) : (this.$store.state.addAlterMartin!=1 && this.$store.state.alterCoverConfig==1) ? JSON.stringify(this.coverConfig) :JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new),
           this.isAlterCrux ? JSON.stringify(this.coverCallback) : (this.$store.state.addAlterMartin!=1 && this.$store.state.alterCoverCallback==1) ? JSON.stringify(this.coverCallback) :JSON.stringify(this.$store.state.addAlterMartin.replenishment_callback_new),
            coverCallback ? 0 :1,
            fullOrderStopLoss ? 0 :1,
            stopLossRatio,
            this.fullOrderSpace,
            this.$store.state.addAlterMartin.strategy_uid,
            this.detailParams.isShowTrendPeriodInput ? 0 : 1
        )
      }
      if (!res.success) return this.$message.error(res.message)
      if (this.isAddMartin == 1) this.$message.success(this.$t('detail_param.strategy_success1'))
      else if (this.isAddMartin == 2) this.$message.success(this.$t('detail_param.strategy_success2'))
      else this.$message.success(this.$t('detail_param.strategy_success3'))
      this.createMartinSuccess()
    },
  //  生成策略成功
    createMartinSuccess(){
      this.$emit('closeDetail',1)
    },

  //  恢复默认参数的函数(公共)
    recoverDefaultParam(policyTypeValue,trendPeriod=0){
      if (this.change_url=='robot'){
        // 当选择策略类型时的默认参数
        if (trendPeriod==0){
          if (policyTypeValue==1){
            this.detailParams= {...martinDefaultParam.detailParams1}
          }
          else if (policyTypeValue==2){
            this.detailParams= {...martinDefaultParam.detailParams2}
          }
          else {
            this.detailParams= {...martinDefaultParam.detailParams3}
          }
        }
        //  选择过策略类型选择去选择策略周期
        if (trendPeriod!=0) this.detailParams= {...martinDefaultParam['detailParams'+policyTypeValue+trendPeriod]}
      }
      else {
        // 当选择策略类型时的默认参数
        if (trendPeriod==0){
          if (policyTypeValue==1){
            this.detailParams= {...martinDefaultParamOkex.detailParams1}
          }
          else if (policyTypeValue==2){
            this.detailParams= {...martinDefaultParamOkex.detailParams2}
          }
          else {
            this.detailParams= {...martinDefaultParamOkex.detailParams3}
          }
        }
        //  选择过策略类型选择去选择策略周期
        if (trendPeriod!=0) this.detailParams= {...martinDefaultParamOkex['detailParams'+policyTypeValue+trendPeriod]}
        this.trading_end.map(item=>{
          if (item.target_coin==this.trading_end_value){
            if (parseInt((item.min_qty_true)*1.5)+1> this.detailParams.firstOrderMoney) this.detailParams.firstOrderMoney=parseInt((item.min_qty_true)*1.5)+1
          }
        })
      }

    //  恢复补仓补仓回调和补仓配置配置的默认参数
      this.$emit('changeCoverConfig',this.policyTypeValue,this.detailParams.trendPeriod,this.detailParams.orderNumber)
    },


    checkinput(value, name, num) {     //输入时  // 通过正则过滤小数点后两位  num为 1 是整数  2为 两位小数   3 是三位小數
      if (num==1){
        if (value.indexOf('.')!=-1) this.$message.warning(this.$t('detail_param.not_input_decimals'))
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\d{0})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      if (num==2){
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      if (num==3){
        var inputValue =
            ("" + value) // 第一步：转成字符串
                .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
                .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                .match(/^\d*(\.?\d{0,3})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      }
      this.detailParams[name]=inputValue
    },
    checkChangeInput(value,name){
      if (name=='orderNumber' && value>31){
        this.detailParams[name]=''
        this.$message.warning(this.$t('detail_param.cover_times_not_greater')+'31 !')
      }
      if (name=='firstOrderTimes' && value<1 && value!=0){
        this.detailParams[name]=''
        this.$message.warning(this.$t('detail_param.tip2')+'1 !')
      }
      if (value==0) {
        this.detailParams[name]=''
        this.$message.warning(this.$t('detail_param.tip3')+'0 !')
      }
      if (value>=100 && name=='stopLossRatio') {
        this.detailParams[name]=''
        this.$message.warning(this.$t('detail_param.tip4')+'100 !')
      }
    },
  //  輸入錯誤提示
    checkInputError(value,name){
      if (name=='firstOrderMoney' && this.change_url=='okex') {
        this.trading_end.map(item=>{
          if (item.target_coin==this.trading_end_value){
            if (parseInt((item.min_qty_true)*1.5)+1> Number(this.detailParams.firstOrderMoney)) {
              this.detailParamsError.firstOrderMoney = true
              this.detailParamsError.firstOrderMoneyContent=parseInt((item.min_qty_true)*1.5)+1
            }
            else this.detailParamsError.firstOrderMoney=false
          }
        })
      }
      else if (name=='firstOrderMoney' && this.change_url=='robot') {
        if ( 15> this.detailParams.firstOrderMoney) {
          this.detailParamsError.firstOrderMoney = true
          this.detailParamsError.firstOrderMoneyContent=15
        }
        else this.detailParamsError.firstOrderMoney=false
      }
      if (value>this.detailParams.stopProfitRatio && name=='stopProfitCallback') this.detailParamsError.stopProfitCallback=true
      else this.detailParamsError.stopProfitCallback=false

    //  处理满单止损的提示
      if (name=='stopLossRatio'){
        // if (this.$store.state.addAlterMartin==1 || this.isAddMartin==2){
        //   let needData = this.coverConfig
        //   let space=0 //仓位
        //   let amount = 0;  //数量
        //   needData.map(item=>{
        //     space = space+ item.multiple*this.fullOrderSpace
        //     amount = amount +  (item.multiple * this.fullOrderSpace)
        //   })
        //
        // }
        // else {
        //   let needData=this.isAlterCrux ? JSON.stringify(this.coverConfig) : (this.$store.state.addAlterMartin!=1 && this.$store.state.alterCoverConfig==1) ? JSON.stringify(this.coverConfig) :JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new)
        //
        //       }

      }
    },
  //  判断哪次循环生效
    decideCycleEffect(name){
        if (this.$store.state.addAlterMartin!=1 && this.isAddMartin!=2){
          if (name=='trendPeriod') this.cycleEffect.trendPeriod=true
          if (name=='firstOrderMoney') this.cycleEffect.firstOrderMoney=true
          if (name=='cycleIndex') this.cycleEffect.cycleIndex=true
          if (name=='firstOrderTimes') this.cycleEffect.firstOrderTimes=true
          if (name=='orderNumber') this.cycleEffect.orderNumber=true
        }
    },
    closeReadOnly(val,num) {
      this.$nextTick(() => {
        if (!val) {
          let input = {}
          switch (num) {
            case 1:
              const {select1} = this.$refs;
              input = select1.$el.querySelector('.el-input__inner');
              break;
            case 2:
              const {select2} = this.$refs;
              input = select2.$el.querySelector('.el-input__inner');
              break;
            case 3:
              const {select3} = this.$refs;
              input = select3.$el.querySelector('.el-input__inner');
              break;
            case 4:
              const {select4} = this.$refs;
              input = select4.$el.querySelector('.el-input__inner');
              break;
            case 5:
              const {select5} = this.$refs;
              input = select5.$el.querySelector('.el-input__inner');
              break;
            case 6:
              const {select6} = this.$refs;
              input = select6.$el.querySelector('.el-input__inner');
              break;
            case 7:
              const {select7} = this.$refs;
              input = select7.$el.querySelector('.el-input__inner');
              break;
            case 8:
              const {select8} = this.$refs;
              input = select8.$el.querySelector('.el-input__inner');
              break;
          }
          input.removeAttribute('readonly');
        }
      })
    },
  },
  watch:{
    trading_end_value(){
      if (this.change_url=='okex') {
        this.trading_end.map(item=>{
          if (item.target_coin==this.trading_end_value){
            if (parseInt((item.min_qty_true)*1.5)+1> Number(this.detailParams.firstOrderMoney)) {
              this.detailParamsError.firstOrderMoney = true
              this.detailParamsError.firstOrderMoneyContent=parseInt((item.min_qty_true)*1.5)+1
            }
            else this.detailParamsError.firstOrderMoney=false
          }
        })
      }
      else if (this.change_url=='robot') {
        if ( 15> this.detailParams.firstOrderMoney) {
          this.detailParamsError.firstOrderMoney = true
          this.detailParamsError.firstOrderMoneyContent=15
        }
        else this.detailParamsError.firstOrderMoney=false
      }
    },
    'policyTypeValue'(){
      if (this.change_url=='okex') {
        this.trading_end.map(item=>{
          if (item.target_coin==this.trading_end_value){
            if (parseInt((item.min_qty_true)*1.5)+1> Number(this.detailParams.firstOrderMoney)) {
              this.detailParamsError.firstOrderMoney = true
              this.detailParamsError.firstOrderMoneyContent=parseInt((item.min_qty_true)*1.5)+1
            }
            else {
              this.detailParamsError.firstOrderMoney = false
            }
          }
        })
      }
      else if (this.change_url=='robot') {
        if ( 15> this.detailParams.firstOrderMoney) {
          this.detailParamsError.firstOrderMoney = true
          this.detailParamsError.firstOrderMoneyContent=15
        }
        else this.detailParamsError.firstOrderMoney=false
      }
    },
    martinComments:{
      handler(){
        console.log(111);
      },
      deep:true
    }



  }
}
</script>

<style scoped>
.detail > div{
  width: calc(50% - 4px);
  display: inline-block;
  vertical-align: top;
}
.detail > div:nth-child(2n+1){
  margin-right: 8px;
}
@media screen and (max-width: 900px){
  .detail > div{
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .detail > div:nth-child(2n+1){
    margin-right: 0;
  }
  .selsectbox{
    width: 100%;
  }

}
.selsectbox{width: 100%}
>>>.selsectbox .el-input__suffix{
  right: 54px;
}

/*後綴的顯示*/
.detail > div{
  position: relative;
}
.detail >div .suffix{
  position: absolute;
  top: 43px;
  vertical-align: middle;
  right: 0;
}
.detail >div .unit-gray{
  font-size: 14px;
  color: #B0B5BA;
  line-height: 32px;
  padding-right: 12px;
  display: inline-block;
}
.detail >div .unit-blue{
  font-size: 14px;
  color: #AB54DB;
  line-height: 32px;
  padding-left: 11px;
  padding-right: 12px;
  display: inline-block;
  cursor: pointer;
  border-left: 1px solid #EAECEF;
}

.detail > div >div:first-child{
  font-size: 12px;
  font-weight: 400;
  color: #72777E;
  line-height: 17px;
  margin: 20px 0 6px 0 ;
  display: inline-block;
  border-bottom: #72777E dashed 0.5px;
  cursor: pointer;
}


/*label 文字*/
.label-div{
  font-size: 12px;
  font-weight: 400;
  color: #72777E;
  line-height: 17px;
  /*padding: 20px 0 0 0 ;*/
  margin:20px 0 6px 0;
  display: inline-block;
  border-bottom: #72777E dashed 0.5px;
  cursor: pointer;
}
.underline-dotted{
  display: inline-block;
  border-bottom: #72777E dashed 0.5px;
  cursor: pointer;
}

/*錯誤提示*/
.error-tip{
  font-size: 12px;
  margin-top: 4px;
  color: #f56c6c;
}
.warn-tip{
  font-size: 12px;
  margin-top: 4px;
  color: rgb(230, 162, 60);
}

/*图标下拉动画*/
.isShowDown{
  animation:ShowDown 0.3s;
  transform:rotate(0deg)
}

@keyframes ShowDown {
  from {transform: rotate(-180deg)}
  to {transform:rotate(0deg);}
}
.isShowUp{
  animation:ShowUp 0.3s;
  transform:rotate(-180deg)
}
@keyframes ShowUp {
  from {transform: rotate(0deg)}
  to {transform:rotate(-180deg);}
}

/*輸入框高度32px*/
>>>.alter-input input{
  height: 32px;
  line-height: 32px;
}
.footer-hover>div:hover{
  background: rgb(238, 239, 241);
}

/*更改select的高度*/
/deep/ .selsectbox  .el-input__inner{
  height:32px;
  line-height: 32px;
}
/deep/ .selsectbox  .el-input__suffix {
  display: inline-flex;
  align-items: center;
  /*font-size: 20px;*/
  /*font-weight: 550;*/
  /*color: red;*/
}

>>>  .el-dialog__header{
  padding: 0;
}
.tipDialog{
  border-radius: 4px;
  text-align: center;
}

/*>>>.el-input.is-disabled .el-input__inner {*/
/*  color: #72777E;*/
/*  background: #F7F9FC;*/
/*}*/
/*>>>.is-disabled input{*/
/*  color: #72777E;*/
/*  background: #F7F9FC !important;*/
/*}*/

.comment-tip{
  max-width: 320px;
  line-height: 20px;
}

>>>.el-select-dropdown{
  /*top: 40px !important;*/
  top: unset !important;
  left: unset !important;
}
@media screen and (max-width: 500px){
  >>>.el-select-dropdown{
    top: 40px !important;
  }
}

.user-change{
  pointer-events: none;
}
.user-change2{
  cursor: not-allowed !important;
}
.user-change3{
  color: #72777E !important;
  opacity: 0.5;
}
.user-change4{
  background: rgb(220, 223, 230);
}
>>>.coins > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__bar {
  opacity: 1 !important;
}
>>>.coin_one > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__bar {
  opacity: 1 !important;
}
>>>.strategy_type > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__bar {
  opacity: 1 !important;
}
>>>.selsectbox > div.el-select-dropdown > div.el-scrollbar > div.el-scrollbar__bar {
  opacity: 1 !important;
}

.coins >>>.el-popper .popper__arrow{
  left: 70px !important;
}

.coin_one >>>.el-popper .popper__arrow{
  left: 70px !important;
}

.selsectbox >>>.el-select-dropdown{
  position: absolute !important;
}

</style>
