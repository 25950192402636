export default {
     detailParams1:{
        trendPeriod:'0',     //趨勢週期
        firstOrderMoney:'15',  //首單額度
        cycleIndex:'0',    //循環次數
        firstOrderTimes:'5',  //首單倍數
        orderNumber:'9',      //做單數量
        stopProfitRatio:'1.5',   //止盈比例
        stopProfitCallback:'0.2',   //止盈回調
        coverConfig:true,           //補倉配置
        coverCallback:false,         //補倉回調
        fullOrderStopLoss:false, //滿單止損
        stopLossRatio:'5',        //止損比例
        isShowTrendPeriodInput:true,
     },
   detailParams2:{
      trendPeriod:'2',     //趨勢週期
      firstOrderMoney:'2',  //首單額度
      cycleIndex:'2',    //循環次數
      firstOrderTimes:'2',  //首單倍數
      orderNumber:'2',      //做單數量
      stopProfitRatio:'2',   //止盈比例
      stopProfitCallback:'2',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'2',        //止損比例
   },
   detailParams3:{
      trendPeriod:'3',     //趨勢週期
      firstOrderMoney:'3',  //首單額度
      cycleIndex:'3',    //循環次數
      firstOrderTimes:'3',  //首單倍數
      orderNumber:'3',      //做單數量
      stopProfitRatio:'3',   //止盈比例
      stopProfitCallback:'3',   //止盈回調
      coverConfig:false,           //補倉配置
      coverCallback:false,         //補倉回調
      fullOrderStopLoss:false, //滿單止損
      stopLossRatio:'3',        //止損比例
   },

   /**
    * 策略类型选择后选择策略类型
    */
   // 策略类型为1 趋势周期为1
   detailParams11:{
      trendPeriod:'1',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是11',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为1 趋势周期为2
   detailParams12:{
      trendPeriod:'2',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是12',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为1 趋势周期为3
   detailParams13:{
      trendPeriod:'3',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是13',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为2 趋势周期为1
   detailParams21:{
      trendPeriod:'1',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是21',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为2 趋势周期为2
   detailParams22:{
      trendPeriod:'2',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是22',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为2 趋势周期为3
   detailParams23:{
      trendPeriod:'3',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是23',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为3 趋势周期为1
   detailParams31:{
      trendPeriod:'1',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是31',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为3 趋势周期为2
   detailParams32:{
      trendPeriod:'2',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是32',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },
   // 策略类型为3 趋势周期为3
   detailParams33:{
      trendPeriod:'3',     //趨勢週期
      firstOrderMoney:'1',  //首單額度
      cycleIndex:'1',    //循環次數
      firstOrderTimes:'我是33',  //首單倍數
      orderNumber:'1',      //做單數量
      stopProfitRatio:'1',   //止盈比例
      stopProfitCallback:'1',   //止盈回調
      coverConfig:true,           //補倉配置
      coverCallback:true,         //補倉回調
      fullOrderStopLoss:true, //滿單止損
      stopLossRatio:'1',        //止損比例
   },


}
